import React, { useEffect, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { LeftSideBarUpper } from "../components/LeftSideBarUpper/LeftSideBarUpper";
import _ from "lodash";
import ButtonElement from "../components/ButtonElement/ButtonElement";
import { startClearFabricByStyle, startGetFabricByStyle } from "../store/actions/fabric";
import { startClearColorFromSF, startGetColorsFromSF } from "../store/actions/color";
import {
    startClearProductAll,
    startGetProductAllBarcode,
    startGetProductAllS_F_C,
    startGetProductAllSFC
} from "../store/actions/viewManagement/productAll";
import BackdropElement from "../components/BackdropElement/BackdropElement";
import ChipElement from "../components/ChipElement/ChipElement";
import SwitchElement from "../components/SwitchElement/SwitchElement";
import Typography from "@material-ui/core/Typography";
import { FormattedMessage, useIntl } from "react-intl";
import IconButtonElement from "../components/IconButtonElement/IconButtonElement";
import {
    backgroundColorUsingStatus,
    getSingleViewData,
    GetTooltipExportStatusComponent,
    GetTooltipStatusColorComponent,
    onErrorSrc
} from "../utils/CommonFunction";
import ReusableModal from "../components/Modal/ReusableModal/ReusableModal";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { startGetFavouriteSettings, startUpdateFavouriteSettings } from "../store/actions/favourites";
import IconExport from "../components/IconExport/IconExport";
import DrawerElement from "../components/DrawerElement/DrawerElement";
import SnackbarElement from "../components/SnackbarElement/SnackbarElement";
import VideoContainer from "./VideoContainer";
import Link from '@material-ui/core/Link';
import { video_columns } from "../assets/resources/video_columns";
import { validateColumnsMaterialSimpleTable } from "../utils/CommonFunction";
import PreserveImageRatio from "../components/PreserveImageRatio/PreserveImageRatio";
import MovieIcon from "@material-ui/icons/Movie";
import PlayerElement from "../components/PlayerElement/PlayerElement";


const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(12, 0, 0, 0),
        flexGrow: 1
    },
    settingsButton: {
        paddingBottom: theme.spacing(2),
    },

    tags: {
        minHeight: "150px",
        textAlign: "left"
    },
    title: {
        fontWeight: "700",
        margin: theme.spacing(0, 0, 1, 0),
        textTransform: 'uppercase',
        fontSize: '.9rem'
    },
    subtitle: {
        fontWeight: "700",
        textAlign: "left"
    },
    field: {
        textAlign: "left",
        fontSize: '.9rem'
    },
    header: {
        textAlign: "left",
        paddingBottom: theme.spacing(2),
        fontSize: '.9rem',
        '& span': {
            paddingRight: "18px"
        }
    },
    headerSeason: {
        textAlign: "left",
        fontWeight: "600 !important",
        fontSize: "20px !important",
        paddingBottom: "8px",
        paddingTop: "10px"
    },
    compactGridContainer: {
        maxWidth: "100px",
        columnGap: "50px !important"
    },
    text: {
        display: "inline-block"
    },
    deleteIcon: {
        float: "right",
        display: "inline-block",
    },
    drilldownImgContainer: {
        position: 'relative',
        width: 200,
        height: 312,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        color: theme.palette.background.main,
        marginBottom: theme.spacing(3),
    },
    detailedImgContainer: {
        position: 'relative',
        width: 426,
        height: 612,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        color: theme.palette.background.main,
        marginLeft: theme.spacing(3),
    },
    drilldownImgs: {
        width: 200,
        height: 280,
        margin: "0 0 0 auto",
    },
    dashedNoImgs: {
        width: 198,
        height: 278,
        margin: "0 0 0 auto",
        border: "2px dashed #383838",
        backgroundColor: theme.palette.primary.main
    },
    detailedImgs: {
        width: 426,
        height: 600,
        margin: "0 0 0 auto",
    },
    settingsList: {
        width: '100%',
        backgroundColor: theme.palette.primary.main
    },
    listItems: {
        display: "inline-block"
    },
    rightItemGrid: {
        flexGrow: 1,
        overflowY: "visible",
        maxHeight: "100vh",
    },
    drawerText: {
        marginLeft: theme.spacing(3)
    },
    emptyViews: {
        minHeight: theme.spacing(6)
    },
    link: {
        color: theme.palette.text.main
    },
    movieFrameContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center'
    },
    movieIcon: {
        position: 'relative',
        left: 8,
        top: 11,
        marginRight: -24,
        color: theme.palette.button.deactive
    },
}));

const ViewManagementContainer = props => {
    const classes = useStyles();
    const { genericStyle } = props;

    const intl = useIntl();
    const [currentContext, setCurrentContext] = useState({ barcode: "", style: "", fabric: "", color: "", style_fabric_color: "", season: "", richiedente: "", seasonality: "" });
    const [currentProducts, setCurrentProducts] = useState([]);
    const [currentSettings, setCurrentSettings] = useState({ compactView: false, statusView: false, exportView: false });
    const [toggleSettings, setToggleSettings] = useState({ compactView: false, statusView: false, exportView: false });
    const [dropdownOpen, setDropdownOpen] = useState({ fabric: false, color: false })
    const [reusableModal, setReusableModal] = useState({
        open: false,
        title: ""
    });
    const [preview, setPreview] = useState({ open: false, content: {} });
    const [snackBar, setSnackBar] = useState({
        open: false,
        type: "error",
        label: "",
        labelId: "",
    });
    const [videoContainer, setVideoContainer] = useState({
        open: false,
        title: ""
    });

    const [videoLink, setVideoLink] = useState([]);
    const [columnsStatus, setColumnsStatus] = useState([]);

    useEffect(() => {
        props.getFavouriteSettings();
        clearCurrentContext();
    }, []);

    useEffect(() => {
        console.log("SETTINGS? ", props.settings, currentSettings);
        if (props.settings) {
            setCurrentSettings(props.settings);
            setToggleSettings(props.settings);
        }
    }, [props.settings])

    useEffect(() => {
        console.log("SETTINGS UPDATE? ", props.settings_update);
        if (props.settings_update && props.settings_update.Status === "OK") {
            setCurrentSettings(toggleSettings);
            setReusableModal({ ...reusableModal, open: false, title: "" })
        }

    }, [props.settings_update])

    useEffect(() => {

        // SEASON = ALL & RICHIEDENTE = ALL & SEASONALITY = ALL
        if (currentContext.season === "All" && currentContext.richiedente === "All" && currentContext.seasonality === "All") {
            setCurrentProducts(props.contextInfo)
        }
        // SEASON != ALL & RICHIEDENTE = ALL & SEASONALITY = ALL
        else if (currentContext.season !== "All" && currentContext.richiedente === "All" && currentContext.seasonality === "All") {
            let contextInfoCopy = _.cloneDeep(props.contextInfo);
            let filtered_contextInfo = contextInfoCopy.filter(c => c.season_code === currentContext.season);
            setCurrentProducts(filtered_contextInfo);
        }
        // SEASON = ALL & RICHIEDENTE != ALL & SEASONALITY = ALL
        else if (currentContext.season === "All" && currentContext.richiedente !== "All" && currentContext.seasonality === "All") {
            let contextInfoCopy = _.cloneDeep(props.contextInfo);
            let filtered_contextInfo = contextInfoCopy.filter(c => c.richiedente === currentContext.richiedente);
            setCurrentProducts(filtered_contextInfo);
        }
        // SEASON = ALL & RICHIEDENTE = ALL & SEASONALITY != ALL
        else if (currentContext.season === "All" && currentContext.richiedente === "All" && currentContext.seasonality !== "All") {
            let contextInfoCopy = _.cloneDeep(props.contextInfo);
            let filtered_contextInfo = contextInfoCopy.filter(c => c.seasonality === currentContext.seasonality);
            setCurrentProducts(filtered_contextInfo);
        }
        // SEASON != ALL & RICHIEDENTE != ALL & SEASONALITY = ALL
        else if (currentContext.season !== "All" && currentContext.richiedente !== "All" && currentContext.seasonality === "All") {
            let contextInfoCopy = _.cloneDeep(props.contextInfo);
            let filtered_contextInfo = contextInfoCopy.filter(c => c.season_code === currentContext.season && c.richiedente === currentContext.richiedente);
            setCurrentProducts(filtered_contextInfo);
        }
        // SEASON != ALL & RICHIEDENTE = ALL & SEASONALITY != ALL
        else if (currentContext.season !== "All" && currentContext.richiedente === "All" && currentContext.seasonality !== "All") {
            let contextInfoCopy = _.cloneDeep(props.contextInfo);
            let filtered_contextInfo = contextInfoCopy.filter(c => c.season_code === currentContext.season && c.seasonality === currentContext.seasonality);
            setCurrentProducts(filtered_contextInfo);
        }
        // SEASON = ALL & RICHIEDENTE != ALL & SEASONALITY != ALL
        else if (currentContext.season === "All" && currentContext.richiedente !== "All" && currentContext.seasonality !== "All") {
            let contextInfoCopy = _.cloneDeep(props.contextInfo);
            let filtered_contextInfo = contextInfoCopy.filter(c => c.richiedente === currentContext.richiedente && c.seasonality === currentContext.seasonality);
            setCurrentProducts(filtered_contextInfo);
        } else {
            let contextInfoCopy = _.cloneDeep(props.contextInfo);
            let filtered_contextInfo = contextInfoCopy.filter(c => c.season_code === currentContext.season && c.richiedente === currentContext.richiedente && c.seasonality === currentContext.seasonality);
            setCurrentProducts(filtered_contextInfo);
        }
    }, [currentContext])


    useEffect(() => {
        if (props.productAll && _.some(currentContext, c => c !== "")) {
            setCurrentProducts(props.contextInfo);
            if (props.statusProduct && props.statusProduct === "OK" && currentContext.barcode !== "") {
                console.log("Why? 1", currentContext);
                setCurrentContext({
                    ...currentContext,
                    style: props.barcodeStyle,
                    fabric: props.barcodeFabric,
                    color: props.barcodeColor,
                    style_fabric_color: props.barcodeStyle + "_" + props.barcodeFabric + "_" + props.barcodeColor,
                    season: "All",
                    richiedente: "All",
                    seasonality: "All"
                });
            } else if (props.statusProduct && props.statusProduct === "OK" && currentContext.barcode === "" && currentContext.style_fabric_color === "") {
                console.log("Why? 2", props.barcodeStyle, props.statusProduct, props.productAll);
                setCurrentContext({
                    ...currentContext,
                    style_fabric_color: props.barcodeStyle + "_" + props.barcodeFabric + "_" + props.barcodeColor,
                    season: "All",
                    richiedente: "All",
                    seasonality: "All"
                });
            } else if (props.statusProduct && props.statusProduct === "OK" && currentContext.style_fabric_color !== "" && currentContext.barcode === "") {
                console.log("Why? 3", currentContext);
                setCurrentContext({
                    ...currentContext,
                    style: props.barcodeStyle,
                    fabric: props.barcodeFabric,
                    color: props.barcodeColor,
                    season: "All",
                    richiedente: "All",
                    seasonality: "All"
                });
            }
        }
        console.log("SEASONS ", props.seasons);
        console.log("SEASONALITY ", props.seasonality);
        console.log("RICHIEDENTI ", props.richiedenti);
        console.log("CONTEXT INFO ", props.contextInfo);

    }, [props.productAll]);

    useEffect(() => {
        if (props.fabricError) {
            setDropdownOpen({ ...dropdownOpen, fabric: false });
            setSnackBar({
                open: true,
                labelId: "view.management.snackbar.no.fabric",
                label: "Sorry, no fabrics found.",
                type: "error",
            });
        }
    }, [props.fabricError])

    useEffect(() => {
        if (props.colorError) {
            setDropdownOpen({ ...dropdownOpen, color: false });
            setSnackBar({
                open: true,
                labelId: "view.management.snackbar.no.color",
                label: "Sorry, no colors found.",
                type: "error",
            });
        }

    }, [props.colorError])

    useEffect(() => {
        if (props.productAllError) {
            setSnackBar({
                open: true,
                labelId: "common.missing_results",
                label: "Sorry, no matching records found.",
                type: "error",
            });
        }
    }, [props.productAllError])

    const clearCurrentContext = () => {
        setDropdownOpen({ fabric: false, color: false });
        setCurrentContext({ barcode: "", style: "", fabric: "", color: "", style_fabric_color: "", season: "", richiedente: "", seasonality: "" });
        console.log("What's happening? ");
        props.clearProductAll();
        props.clearFabricByStyle();
        props.clearColorFromSF();
        setPreview({ open: false, content: {} });
    }

    const onChangeContextHandler = event => {
        console.log(event.target.value, event.target.name);
        let fieldName = event.target.name;
        let value = event.target.value;
        switch (fieldName) {
            case "barcode":
                setCurrentContext({
                    ...currentContext,
                    barcode: value.toUpperCase()
                })
                break;
            case "style":
                setCurrentContext({
                    ...currentContext,
                    style: value.toUpperCase(),
                });
                break;
            case "fabric":
                setCurrentContext({
                    ...currentContext,
                    fabric: value,
                    color: ""
                });
                setDropdownOpen({ ...dropdownOpen, fabric: false });
                handleSetFocus("color");
                props.getColorsfromSF(currentContext.style, value);
                break;
            case "color":
                setCurrentContext({ ...currentContext, color: value });
                setDropdownOpen({ ...dropdownOpen, color: false });
                props.getProductAllSFC(currentContext.style, currentContext.fabric, value);
                break;
            case "style_fabric_color":
                setCurrentContext({ ...currentContext, style_fabric_color: value });
                break;
            case "season":
                setCurrentContext({ ...currentContext, season: value });
                break;
            case "richiedente":
                setCurrentContext({ ...currentContext, richiedente: value });
                break;
            case "seasonality":
                setCurrentContext({ ...currentContext, seasonality: value });
                break;
            default:
                break;
        }
    }



    const handleContextKeyPress = event => {
        console.log(event.target.id);
        let field = event.target.name;
        let value = event.target.value;
        if (field === "barcode" && event.key === "Enter") {
            props.getProductAllBarcode(value);
        } else if (field === "style" && event.key === "Enter") {
            console.log("style value", value.toUpperCase());
            props.getFabricByStyle(value.toUpperCase());
            handleSetFocus("fabric");
        } else if (field === "style_fabric_color" && event.key === "Enter") {
            console.log("style_fabric_color value", value.toUpperCase());
            props.getProductAllS_F_C(value.toUpperCase());
        }
    }

    const titleDeleteButton =
        <div>
            <div className={classes.text}>
                <Typography className={classes.title}>
                    {intl.formatMessage({
                        id: "view.management.title.shooting.data",
                        defaultMessage: "Shooting data"
                    })}
                </Typography>
            </div>
            {currentContext.barcode !== "" || currentContext.style !== "" || currentContext.style_fabric_color !== "" ?
                <div className={classes.deleteIcon}><IconButtonElement
                    labelId={"view.management.tooltip.clear.context"}
                    label={"Clear Context"}
                    icon={"close"}
                    fontSize={"small"}
                    onClick={clearCurrentContext} /> </div>
                : <div />}
        </div>


    const barcodeInput = {
        type: "input",
        name: "barcode",
        value: currentContext["barcode"],
        handleChange: onChangeContextHandler,
        onKeyPress: handleContextKeyPress,
        placeholder: "Product ID",
        placeholderId: "admin.transactions.product_id",
        icon: "local_offer",
        disabled: currentContext["style"] !== "" || currentContext["style_fabric_color"] !== ""
    }

    const styleInput = {
        type: "input",
        name: "style",
        value: currentContext["style"],
        handleChange: onChangeContextHandler,
        onKeyPress: handleContextKeyPress,
        placeholder: "Style",
        placeholderId: "product.style",
        icon: "style",
        disabled: currentContext["barcode"] !== "" || currentContext["style_fabric_color"] !== ""
    }

    const fabricDropdown = {
        type: currentContext["barcode"] !== "" || currentContext["style_fabric_color"] !== "" ? "input" : "dropdown",
        name: "fabric",
        open: dropdownOpen["fabric"],
        value: currentContext["fabric"],
        onChangeHandler: onChangeContextHandler,
        icon: "push_pin",
        placeholder: "Fabric",
        placeholderId: "search.keys.fabric",
        label: { translateKey: "search.keys.fabric", defaultText: "Fabric" },
        options: props.fabric || [],
        loading: props.loadingFabric,
        disabled: currentContext["barcode"] !== "" || currentContext["style_fabric_color"] !== ""
    }

    const colorDropdown = {
        type: currentContext["barcode"] !== "" || currentContext["style_fabric_color"] !== "" ? "input" : "dropdown",
        name: "color",
        open: dropdownOpen["color"],
        value: currentContext["color"],
        onChangeHandler: onChangeContextHandler,
        icon: "palette",
        label: { translateKey: "product.color", defaultText: "Color" },
        options: props.color || [],
        placeholder: "Color",
        placeholderId: "product.color",
        loading: props.loadingColor,
        disabled: currentContext["barcode"] !== "" || currentContext["style_fabric_color"] !== ""
    }

    const styleFabricColorInput = {
        type: "input",
        name: "style_fabric_color",
        value: currentContext.style_fabric_color,
        handleChange: onChangeContextHandler,
        onKeyPress: handleContextKeyPress,
        placeholder: "Style_Fabric_Color",
        placeholderId: "view.management.style_fabric_color",
        icon: "file_copy",
        disabled: currentContext["style"] !== "" || currentContext["barcode"] !== ""
    }

    const seasonDropdown = {
        type: "dropdown",
        name: "season",
        value: currentContext["season"],
        onChangeHandler: onChangeContextHandler,
        icon: "date_range",
        label: { translateKey: "view.management.all.seasons", defaultText: "All Seasons" },
        options: props.seasons,
        disabled: !props.productAll || props.productAll.length === 0,
        loading: props.loadingProductAll,
    }

    const seasonalityDropdown = {
        type: "dropdown",
        name: "seasonality",
        value: currentContext["seasonality"],
        onChangeHandler: onChangeContextHandler,
        icon: "date_range",
        label: { translateKey: "view.management.all.seasonalities", defaultText: "All Seasonalities" },
        options: props.seasonalities,
        disabled: !props.productAll || props.productAll.length === 0,
        loading: props.loadingProductAll,
    }

    const richiedenteDropdown = {
        type: "dropdown",
        name: "richiedente",
        value: currentContext["richiedente"],
        onChangeHandler: onChangeContextHandler,
        icon: "person",
        label: { translateKey: "view.management.all.requesters", defaultText: "All Requesters" },
        options: props.richiedenti,
        disabled: !props.productAll || props.productAll.length === 0,
        loading: props.loadingProductAll,
    };


    const groupElements = [titleDeleteButton, barcodeInput, "space", styleInput, fabricDropdown, colorDropdown, "space", styleFabricColorInput, "space", seasonDropdown, richiedenteDropdown, seasonalityDropdown];

    /* ****************************** FOCUS BLUR HANDLERS ************************************* */
    const handleSetFocus = elem => {
        console.log("FOCUS ON: ", elem);
        try {
            window.setTimeout(function () {
                document.getElementById(elem).focus();
                setDropdownOpen({ ...dropdownOpen, [elem]: true })
            }, 0);
        } catch {
            console.log("Cannot read property 'focus' of null");
        }
    };

    /*const handleSetBlur = elem => {
        window.setTimeout(function () {
            document.getElementById(elem).blur();
        }, 0);
    };*/

    const closeReusableModal = () => {
        setReusableModal({
            open: false,
            title: ""
        })
        setToggleSettings(currentSettings);
    }

    const handleToggle = (event) => {
        console.log("Toggling: ", event.target.name, event.target.checked)
        setToggleSettings({ ...toggleSettings, [event.target.name]: event.target.checked })
    }

    const handleImgClick = (event, v) => {
        console.log("Clicking on: ", event, v);
        if (!v.is_empty) setPreview({ open: true, content: v });
    }

    const getNumberOfVideo = (elem) => {
        let tot = 0;

        for (let e in elem) {
            tot = tot + elem[e].videos.length;
        }


        return tot;
    }

    const getVideoLink = (elem) => {

        const columns = validateColumnsMaterialSimpleTable(intl, video_columns);
        columns.forEach(column => {
            if (column.field === 'url') {
                column.render = rowData =>
                    <Typography>
                        <PlayerElement url={rowData.url} />
                        {/*<Link href={rowData.url}*/}
                        {/*        target="_blank"*/}
                        {/*      onClick={() => {*/}
                        {/*          PlayerElement(rowData.ur);*/}
                        {/*      }}*/}
                        {/*        rel="noreferrer"*/}
                        {/*        variant="inherit"*/}
                        {/*        data-cy="doc-link"*/}
                        {/*        className={classes.link}>*/}
                        {/*        {'Play'}*/}
                        {/*</Link>*/}
                    </Typography>
            }
        });

        setColumnsStatus(columns);



        console.log("video columns ", columns)

        let videoLinkTmp = [];
        for (let e in elem) {
            if (elem[e].videos.length > 0) {
                let video = elem[e].videos;
                for (let v in video) {
                    videoLinkTmp = [...videoLinkTmp,
                    {
                        name: video[v].asset_name,
                        url: video[v].asset_url
                    }]
                }
            }
        }

        videoLinkTmp.sort((video1, video2) => video1.name.localeCompare(video2.name))
        setVideoLink(videoLinkTmp)

    }

    const closeVideoContainer = () => {
        setVideoContainer({
            open: false,
            title: ""
        })
        setToggleSettings(currentSettings);
    }

    return (
        <div className={classes.root}>
            <BackdropElement loading={props.loadingProductAll || props.loadingFavouriteSettings} />
            <SnackbarElement
                open={snackBar.open}
                type={snackBar.type}
                label={snackBar.label}
                labelId={snackBar.labelId}
                handleClose={() =>
                    setSnackBar({
                        open: false,
                        label: "",
                        labelId: "",
                        type: "error",
                    })
                }
            />
            <ReusableModal
                reusableModal={reusableModal}
                greenButtonClick={() => props.updateFavouriteSettings(toggleSettings)}
                closeReusableModal={closeReusableModal}
            >
                <List className={classes.settingsList}>
                    <ListItem>
                        <ListItemText id="compact" primary="Compact view" />
                        <ListItemSecondaryAction>
                            <SwitchElement
                                edge="end"
                                name="compactView"
                                onChange={handleToggle}
                                checked={toggleSettings["compactView"]}
                                inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                            />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                        <div>
                            <ListItemText id="view" primary="View Status" className={classes.listItems} />
                            {GetTooltipStatusColorComponent("right", genericStyle)}
                        </div>
                        <ListItemSecondaryAction>
                            <SwitchElement
                                edge="end"
                                name="statusView"
                                onChange={handleToggle}
                                checked={toggleSettings["statusView"]}
                                inputProps={{ 'aria-labelledby': 'switch-list-label-bluetooth' }}
                            />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                        <div>
                            <ListItemText id="export" primary="Export Status" className={classes.listItems} />
                            {GetTooltipExportStatusComponent("right", genericStyle)}
                        </div>
                        <ListItemSecondaryAction>
                            <SwitchElement
                                edge="end"
                                name="exportView"
                                onChange={handleToggle}
                                checked={toggleSettings["exportView"]}
                                inputProps={{ 'aria-labelledby': 'switch-list-label-bluetooth' }}
                            />
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            </ReusableModal>
            <Grid container
                direction='row'
                justify='flex-start'
                spacing={4}>
                <Grid item xs={3}>
                    <div className={classes.settingsButton}>
                        <ButtonElement
                            labelId={"common.button.label.settings"}
                            label={"Settings"}
                            onSubmit={() => setReusableModal({
                                open: true,
                                title: intl.formatMessage({
                                    id: "common.button.label.settings",
                                    defaultMessage: "Settings",
                                })
                            })}
                            icon={"settings"}
                        /></div>
                    <LeftSideBarUpper groupElements={groupElements} />
                    {props.productInfo.length > 0 && (currentContext.barcode !== "" || currentContext.fabric !== "" || currentContext.style_fabric_color !== "") &&
                        <Grid container spacing={1} style={{ marginTop: '8px' }}>
                            <Grid item >
                                <Typography className={classes.subtitle}>
                                    {intl.formatMessage({
                                        id: "product.characteristic.data",
                                        defaultMessage: "Characteristic data of the product",
                                    })}
                                </Typography>
                            </Grid>
                            <Grid item className={classes.tags}>
                                {props.productInfo.filter(obj => obj.value !== "").map(obj => (
                                    <ChipElement
                                        key={obj.key}
                                        cssType={"info"}
                                        label={obj.value}
                                    />
                                ))}
                            </Grid>
                        </Grid>
                    }
                </Grid>
                {props.productAll && props.statusProduct === "OK" &&
                    <Grid item xs={6} className={classes.rightItemGrid}>
                        <DrawerElement open={preview.open} anchor="right" handleClose={() => setPreview({ open: false, content: {} })}>
                            <Grid container item xs={9} alignItems="flex-end" >
                                <div className={`${classes.detailedImgContainer}`}>
                                    {preview.content.asset_type === "VIDEO" ?
                                        <div className={classes.movieFrameContainer}>
                                            <MovieIcon className={classes.movieIcon} />
                                            <PreserveImageRatio
                                                src={preview.content.img_url || ""}
                                                height={600}
                                                width={426}
                                                isVideo
                                            />
                                        </div>
                                        :
                                        <PreserveImageRatio
                                            src={preview.content.img_url || ""}
                                            className={classes.detailedImgs}
                                            alt={"Image not found"}
                                            height={600}
                                            width={300}
                                            onError={(event) => onErrorSrc(event)}
                                        />
                                    }
                                </div>
                                <div className={classes.drawerText}>
                                    {getSingleViewData(preview.content, genericStyle, intl, "vm")}
                                </div>
                            </Grid>
                        </DrawerElement>
                        {currentProducts.map((c, i) =>
                            <div key={i}>
                                <div className={classes.header} >
                                    <Typography className={classes.headerSeason}>
                                        {c.season}
                                    </Typography>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <strong>
                                                        <FormattedMessage
                                                            id="common.dropdown.label.richiedente"
                                                            defaultMessage="Requester"
                                                        />:</strong>
                                                </td>
                                                <td>
                                                    <span>{` ${c.richiedente}`}</span>
                                                </td>
                                                <td><strong>
                                                    <FormattedMessage
                                                        id="common.dropdown.label.service"
                                                        defaultMessage="Service"
                                                    />
                                                    :</strong>
                                                </td>
                                                <td>
                                                    <span>{` ${c.service}`}</span>
                                                </td>
                                                <td><strong>
                                                    <FormattedMessage
                                                        id="common.dropdown.label.seasonality"
                                                        defaultMessage="Seasonality"
                                                    />
                                                    :</strong>
                                                </td>
                                                <td>
                                                    <span>{` ${c.seasonality}`}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>
                                                        <FormattedMessage
                                                            id="search.keys.window.delivery.code"
                                                            defaultMessage="Window Delivery Code"
                                                        />
                                                        :
                                                    </strong>
                                                </td>
                                                <td>
                                                    <span>{` ${c.delivery}`}</span>
                                                </td>
                                                <td>
                                                    <strong>
                                                        <FormattedMessage
                                                            id="search.keys.start.custom.delivery"
                                                            defaultMessage="Start Custom Delivery"
                                                        />
                                                        :
                                                    </strong>
                                                </td>
                                                <td>
                                                    <span>{` ${c.start_custom_delivery}`}</span>
                                                </td>
                                                <td>
                                                    <strong>
                                                        <FormattedMessage
                                                            id="search.keys.end.custom.delivery"
                                                            defaultMessage="End Custom Delivery"
                                                        />
                                                        :
                                                    </strong>
                                                </td>
                                                <td>
                                                    <span>{` ${c.end_custom_delivery}`}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>
                                                        <FormattedMessage
                                                            id="product.versamento_previsto"
                                                            defaultMessage="Previsional IN Store"
                                                        />
                                                        :
                                                    </strong>
                                                </td>
                                                <td>
                                                    <span>{` ${c.previsional_in_store}`}</span>
                                                </td>
                                                <td>
                                                    <strong>
                                                        <FormattedMessage
                                                            id="product.versamento_effettivo"
                                                            defaultMessage="Real IN Store Date"
                                                        />
                                                        :
                                                    </strong>
                                                </td>
                                                <td>
                                                    <span>{` ${c.real_in_store}`}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>
                                                        <FormattedMessage
                                                            id="view.management.photolab.info.ph.in"
                                                            defaultMessage="Ph in"
                                                        />
                                                        :
                                                    </strong>
                                                </td>
                                                <td>
                                                    <span>{c.photolabInfo.length > 0 ? ` ${c.photolabInfo[0].date_in}` : ""}</span>
                                                </td>
                                                <td>
                                                    <strong>
                                                        <FormattedMessage
                                                            id="view.management.photolab.info.ph.out"
                                                            defaultMessage="Ph out"
                                                        />
                                                        :
                                                    </strong>
                                                </td>
                                                <td>
                                                    <span>{c.photolabInfo.length > 0 ? ` ${c.photolabInfo[0].date_out}` : ""}</span>
                                                </td>
                                                <td>
                                                    <strong>
                                                        <FormattedMessage
                                                            id="view.management.photolab.info.source"
                                                            defaultMessage="Source"
                                                        />
                                                        :
                                                    </strong>
                                                </td>
                                                <td>

                                                    <span>{c.photolabInfo.length > 0 ? ` ${c.photolabInfo[0].source}` : ""}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>
                                                        <FormattedMessage
                                                            id="product.asset.total_videos"
                                                            defaultMessage="Total video"
                                                        />:</strong>
                                                </td>

                                                <td>
                                                    <span>{getNumberOfVideo(c.videos_by_richiedente_service)}</span>

                                                </td>
                                                {
                                                    getNumberOfVideo(c.videos_by_richiedente_service) > 0 &&

                                                    <td>
                                                        <Link 
                                                            onClick={() => {
                                                                getVideoLink(c.videos_by_richiedente_service)

                                                                setVideoContainer({
                                                                    open: true,
                                                                    title: intl.formatMessage({
                                                                        id: "product.asset.videos",
                                                                        defaultMessage: "Video",
                                                                    })
                                                                })
                                                            }}
                                                        >
                                                            <b style={{ 
                                                                textDecoration: 'underline', 
                                                                cursor: 'pointer',
                                                                color: 'white'
                                                            }}>
                                                                Show video list
                                                            </b>
                                                        </Link>
                                                    </td>
                                                }


                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <Grid item container xs={12} spacing={1}>
                                    {(_.some(c.views, el => el.is_empty === false) || currentSettings.compactView) ? c.views.map((v, key) =>
                                        !v.is_empty && !currentSettings.compactView ?
                                            <Grid
                                                key={key}
                                                item
                                                container
                                                xs={6}
                                                direction="row"
                                                justify="flex-start"
                                            >
                                                <Grid container item xs={5} >
                                                    <div className={`${classes.drilldownImgContainer} ${backgroundColorUsingStatus(currentSettings.statusView ? v.status_name : "disabled", genericStyle)}`}>
                                                        <>
                                                            {currentSettings.exportView && v.export_status && <IconExport exportStatus={v.export_status} />}
                                                            {v.asset_type === "VIDEO" ?
                                                                <div
                                                                    className={classes.movieFrameContainer}
                                                                    onClick={(event) => handleImgClick(event, v)}
                                                                >
                                                                    <MovieIcon className={classes.movieIcon} />
                                                                    <PreserveImageRatio
                                                                        src={v.img_url || ""}
                                                                        height={280}
                                                                        width={200}
                                                                        isVideo
                                                                    />
                                                                </div>
                                                                :
                                                                <div className={classes.movieFrameContainer}>
                                                                    <PreserveImageRatio
                                                                        src={v.img_url || ""}
                                                                        className={classes.drilldownImgs}
                                                                        alt={"Image not found"}
                                                                        height={280}
                                                                        width={200}
                                                                        onClick={(event) => handleImgClick(event, v)}
                                                                        onError={(event) => onErrorSrc(event)}
                                                                    />
                                                                </div>
                                                            }</>
                                                        <Typography style={{ marginBottom: 4 }}>{v.original_view}</Typography>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <div className={classes.drawerText}>{getSingleViewData(v, genericStyle, intl, "vm")}</div>
                                                </Grid>
                                            </Grid> : !v.is_empty && currentSettings.compactView ?
                                                <Grid
                                                    key={key}
                                                    item
                                                    container
                                                    xs={3}
                                                    direction="row"
                                                    justify="flex-start"
                                                >
                                                    <Grid container item xs={5} >
                                                        <div className={`${classes.drilldownImgContainer} ${backgroundColorUsingStatus(currentSettings.statusView ? v.status_name : "disabled", genericStyle)}`}>
                                                            <>
                                                                {currentSettings.exportView && v.export_status && <IconExport exportStatus={v.export_status} />}
                                                                {v.asset_type === "VIDEO" ?
                                                                    <div
                                                                        className={classes.movieFrameContainer}
                                                                        onClick={(event) => handleImgClick(event, v)}
                                                                    >
                                                                        <MovieIcon className={classes.movieIcon} />
                                                                        <PreserveImageRatio
                                                                            src={v.img_url || ""}
                                                                            height={280}
                                                                            width={200}
                                                                            isVideo
                                                                        />
                                                                    </div>
                                                                    :
                                                                    <div className={classes.movieFrameContainer}>
                                                                        <PreserveImageRatio
                                                                            src={v.img_url || ""}
                                                                            className={classes.drilldownImgs}
                                                                            alt={"Image not found"}
                                                                            height={280}
                                                                            width={200}
                                                                            onClick={(event) => handleImgClick(event, v)}
                                                                            onError={(event) => onErrorSrc(event)}
                                                                        />
                                                                    </div>
                                                                }</>
                                                            <Typography style={{ marginBottom: 4 }}>{v.original_view}</Typography>
                                                        </div>
                                                    </Grid>
                                                    {/*<Grid item xs={6}>
                                                        <div className={classes.drawerText}>{getSingleViewData(v, genericStyle, intl, "vm")}</div>
                                                            </Grid>*/}
                                                </Grid> : !v.is_empty && currentSettings.compactView ?
                                                    <Grid
                                                        key={key}
                                                        item
                                                        container
                                                        xs={3}
                                                        direction="row"
                                                        justify="flex-start"
                                                    >
                                                        <Grid container item xs={3} >
                                                            <div className={`${classes.drilldownImgContainer} ${backgroundColorUsingStatus(currentSettings.statusView ? v.status_name : "disabled", genericStyle)}`}>
                                                                <>
                                                                    {currentSettings.exportView && v.export_status && <IconExport exportStatus={v.export_status} />}
                                                                    <PreserveImageRatio
                                                                        src={v.img_url || ""}
                                                                        className={classes.drilldownImgs}
                                                                        alt={"Image not found"}
                                                                        height={280}
                                                                        width={200}
                                                                        onClick={(event) => handleImgClick(event, v)}
                                                                        onError={(event) => onErrorSrc(event)}
                                                                    /> </>
                                                                <Typography style={{ marginBottom: 4 }}>{v.original_view}</Typography>
                                                            </div>
                                                        </Grid>
                                                    </Grid> : v.is_empty && currentSettings.compactView ? <Grid
                                                        key={key}
                                                        item
                                                        container
                                                        xs={3}
                                                        direction="row"
                                                        justify="flex-start"
                                                    >
                                                        <Grid container item xs={3}>
                                                            <div
                                                                className={`${classes.drilldownImgContainer} ${backgroundColorUsingStatus("disabled", genericStyle)}`}>
                                                                <div className={classes.dashedNoImgs} />
                                                                <Typography
                                                                    style={{ marginBottom: 4 }}>{v.original_view}</Typography>
                                                            </div>
                                                        </Grid>
                                                    </Grid> : ""
                                    ) : <div className={classes.emptyViews} />}
                                </Grid>
                            </div>
                        )}
                    </Grid>
                }

            </Grid>

            <VideoContainer
                columnsStatus={columnsStatus}
                videoLink={videoLink}
                videoContainer={videoContainer}
                closeVideoContainer={closeVideoContainer} />

        </div >
    );
};


const mapStateToProps = state => ({
    loadingFavouriteSettings: state.favouritesReducer.loadingFavouriteSettings,
    settings: state.favouritesReducer.settings,
    settings_update: state.favouritesReducer.response,
    loadingFabric: state.fabricReducer.loadingFabric,
    fabric: _.orderBy(_.toArray(_.mapValues(state.fabricReducer.fabric, function (o) {
        return {
            label: o.fabric,
            value: o.fabric,
        };
    })), ['value'], ['asc']),
    fabricError: state.fabricReducer.error,
    loadingColor: state.colorReducer.loadingColor,
    color: _.orderBy(_.toArray(_.mapValues(state.colorReducer.color, function (o) {
        return {
            label: o.color,
            value: o.color,
        };
    })), ['value'], ['asc']),
    colorError: state.colorReducer.error,
    loadingProductAll: state.productAllReducer.loadingProductAll,
    productAll: state.productAllReducer.productAll,
    barcodeStyle: state.productAllReducer.style,
    barcodeFabric: state.productAllReducer.fabric,
    barcodeColor: state.productAllReducer.color,
    statusProduct: state.productAllReducer.status,
    productAllError: state.productAllReducer.error,
    contextInfo: _.toArray(
        _.mapValues(state.productAllReducer.contextInfo, (o) => {
            return {
                season: o.season_description || "",
                season_code: o.season_code || "",
                richiedente: o.richiedente_name || "",
                seasonality: o.seasonality || "",
                service: o.service_name || "",
                views: _.orderBy(o.views, ['seq_order'], ['asc']),
                end_custom_delivery: o.end_custom_delivery || "",
                start_custom_delivery: o.start_custom_delivery || "",
                delivery: o.window_delivery_code || "",
                previsional_in_store: o.previsional_in_store || "",
                real_in_store: o.real_in_store || "",
                videos_by_richiedente_service: o.videos_by_richiedente_service || "",
                photolabInfo: _.toArray(_.mapValues(o.photolabinfo, p => {
                    return {
                        date_in: p.date_in || "",
                        date_out: p.date_out || "",
                        source: p.dress_origin_type || "",
                        photolab: p.photolab_name || ""
                    }
                }))
            };
        })),
    seasons: _.orderBy(_.concat(_.uniqBy(_.toArray(
        _.mapValues(state.productAllReducer.contextInfo, (o, index) => {
            return {
                key: parseInt(index) + 1,
                label: o.season_description,
                value: o.season_code,
            };
        })
    ), 'value'
    ), {
        key: 0,
        value: 'All',
        label: <FormattedMessage id="view.management.all.seasons" defaultMessage="All Seasons" />
    }), ['key'],
        ['asc']),
    richiedenti: _.orderBy(_.concat(_.uniqBy(_.toArray(
        _.mapValues(state.productAllReducer.contextInfo, (o, index) => {
            return {
                key: parseInt(index) + 1,
                label: o.richiedente_name,
                value: o.richiedente_name,
            };
        })
    ), 'value'
    ), {
        key: 0,
        value: 'All',
        label: <FormattedMessage id="view.management.all.requesters" defaultMessage="All Requesters" />
    }), ['key'],
        ['asc']),
    seasonalities: _.orderBy(_.concat(_.uniqBy(_.toArray(
        _.mapValues(state.productAllReducer.contextInfo, (o, index) => {
            return {
                key: parseInt(index) + 1,
                label: o.seasonality,
                value: o.seasonality,
            };
        })
    ), 'value'
    ), {
        key: 0,
        value: 'All',
        label: <FormattedMessage id="view.management.all.seasonalities" defaultMessage="All Seasonalities" />
    }), ['key'],
        ['asc']),
    productInfo: _.toArray(
        _.mapValues(state.productAllReducer.productInfo, (o, index) => {
            return {
                key: index,
                name: o.name,
                value: o.name,
            };
        })
    )
});

const mapDispatchToProps = dispatch => ({
    getFavouriteSettings: () => dispatch(startGetFavouriteSettings()),
    getFabricByStyle: (style) => dispatch(startGetFabricByStyle(style)),
    getColorsfromSF: (style, fabric) => dispatch(startGetColorsFromSF(style, fabric)),
    getProductAllSFC: (style, fabric, color) => dispatch(startGetProductAllSFC(style, fabric, color)),
    getProductAllBarcode: (barcode) => dispatch(startGetProductAllBarcode(barcode)),
    getProductAllS_F_C: (style_fabric_color) => dispatch(startGetProductAllS_F_C(style_fabric_color)),
    updateFavouriteSettings: (settings) => dispatch(startUpdateFavouriteSettings(settings)),
    clearProductAll: () => dispatch(startClearProductAll()),
    clearColorFromSF: () => dispatch(startClearColorFromSF()),
    clearFabricByStyle: () => dispatch(startClearFabricByStyle())
});


export default connect(mapStateToProps, mapDispatchToProps)(ViewManagementContainer);