import React, {useEffect, useState} from "react";
import _ from "lodash";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import MenuTabsElement from "../../components/MenuTabsElement/MenuTabsElement";
import BackdropElement from "../../components/BackdropElement/BackdropElement";
import Typography from "@material-ui/core/Typography";
import DropDownElement from "../../components/DropDownElement/DropDownElement";
import {startGetRichiedente} from "../../store/actions/richiedente";
import {startGetServiceByRichiedenteFull} from "../../store/actions/service";
import {startGetSeasons} from "../../store/actions/season";
import {startGetBrand} from "../../store/actions/brand";
import {startGetGender} from "../../store/actions/gender";
import {startGetEvent} from "../../store/actions/showroom/event";
import {
    clearContext, 
    clearUpdateAsset, 
    startGetCompositionContext, 
    startGetShowroomDefaultContext, 
    updateShowroomDefaultContext
} from "../../store/actions/showroom/context";
import {connect} from "react-redux";
import {filterActiveSeasons, getAppliedFilter, checkAction} from "../../utils/CommonFunction";
import ButtonElement from "../../components/ButtonElement/ButtonElement";
import {useIntl} from "react-intl";
import DropzoneDialogElement from "../../components/DropzoneDialogElement/DropzoneDialogElement";
import {
    clearUploadShowroomAssets,
    loadAssets,
    physicalUploadComplete,
    startUploadShowroomAssets
} from "../../store/actions/showroom/composition/uploadAssets";
import SnackbarElement from "../../components/SnackbarElement/SnackbarElement";
import {clearNewGallery, clearShowroomGalleries} from "../../store/actions/showroom/composition/galleries";
import LooksContainer from "./TabViewContainers/LooksContainer";
import GalleriesContainer from "./TabViewContainers/GalleriesContainer";
import AccordionElement from "../../components/AccordionElement/AccordionElement";
import {clearLooks} from "../../store/actions/showroom/composition/looks";
import {clearLook, clearProductLook} from "../../store/actions/showroom/composition/look";
import {clearLookcastExport} from "../../store/actions/showroom/composition/exportToLookcast";
import {clearGalleryDownloadInfo} from "../../store/actions/showroom/composition/downloads";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(12)
    },
    bottomActions: {
        position: "absolute",
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        left: theme.spacing(2)
    },
    uploadAssetLabel: {
        display: 'flex',
        justifyContent: 'flex-start',
        marginBottom: theme.spacing(-2)
    }
}));

const CompositionShowroomContainer = props => {
    const classes = useStyles();
    const intl = useIntl();

    const [viewType, setViewType] = useState(0);
    const [contextStatus, setContextStatus] = useState({
        richiedente: '',
        service: '',
        richiedenteService: null,
        season: '',
        brand: '',
        gender: '',
        event: ''
    });
    const [draftStatus, setDraftStatus] = useState('');
    const [disabledUploadStatus, setDisabledUploadStatus] = useState(true);
    const [dropzoneDialogOpen, setDropzoneDialogOpen] = useState(false);
    const [snackBarStatus, setSnackBarStatus] = useState({
        show: false,
        message: '',
        type: '',
        messageAbout: ''
    });
    const [uploadState, setUploadState] = useState({});
    const [backdropState, setBackdropState] = useState({ loading: false, label: '' });
    const [contextAccordionOpenState, setContextAccordionOpenState] = useState(true);

    const draftOptions = [{ label: 'Draft', value: true }, { label: 'Final', value: false }];

    /**
     * Fetching dropdown options: except service that is fetch after applicant is selected.
     */
    useEffect(() => {
        console.log('Ottengo i valori per i dropdown!!');
        // Getting context info
        props.getRichiedente();
        props.getSeason();
        props.getBrand();
        props.getGender();
        props.getEvents();

        // Getting default context
        props.getDefaultContext();

        // component willUnmount
        return () => {
            // Clear redux state
            props.clearUpload();
            props.clearGalleries();
            props.clearContext();
            props.clearLooks();
            // TODO: aggiungere le clear di redux (vedi closeSnackbar)
        }
    }, []);


    /**
     * Fetch the context ID.
     */
    useEffect(() => {
        const isContextSet = !Object.values(contextStatus).map(value => value !== '').includes(false);
        
        if (isContextSet) {
            props.updateDefaultContext(contextStatus);

            console.log('Getting context!');
            if (props.errorContext || props.showroomContext) {
                props.clearContext();
            }
            props.getContext(
                contextStatus.richiedenteService,
                contextStatus.season,
                contextStatus.brand,
                contextStatus.gender,
                contextStatus.event
            );
        }
    }, [contextStatus]);

    /**
     * Set the default context received
     */
    useEffect(() => {
        if (props.showroomDefaultContext) {
            console.log("Set default context:", props.showroomDefaultContext);

            if ( props.showroomDefaultContext.hasOwnProperty('richiedente') ) {
                props.getServicesByRichiedente(props.showroomDefaultContext.richiedente);
            }
            
            setContextStatus(contextStatus => ({ ...contextStatus, ...props.showroomDefaultContext }));
        }
    }, [props.showroomDefaultContext])

    /**
     * Enable/Disable uploadAsset button.
     */
    useEffect(() => {
        const isContextSet = !Object.values(contextStatus).map(value => value !== '' && value !== false).includes(false);

        if (isContextSet && draftStatus !== '' && viewType !== 0 && checkAction("composition.upload_images", props.auth.actions)) {
            setDisabledUploadStatus(false);
        } else if (!disabledUploadStatus) {
            // Case: it was enabled but now something change and uploadButton has to be disabled again. This check
            // limit update of state during first load of the page.
            setDisabledUploadStatus(true);
        }
    }, [contextStatus, draftStatus, viewType]);


    /* ************************************* HANDLER OF MESSAGE ************************************************ */
    /**
     * Handler of error message during fetching/creating of galleries.
     */
    useEffect(() => {
        if (!props.loadingShowroomGalleries && (props.errorGalleries || props.errorFinal || props.errorDraft)) {
            if (props.errorGalleries && props.errorFinal && props.errorDraft) {
                setSnackBarStatus({
                    show: true,
                    message: intl.formatMessage({
                        id: 'showroom.loading.galleries.error',
                        defaultMessage: 'Fetch of galleries failed.'
                    }),
                    type: 'error'
                });
            } else if (props.errorGalleries) {
                setSnackBarStatus({
                    show: true,
                    message: intl.formatMessage({
                        id: 'showroom.loading.galleries.error',
                        defaultMessage: 'Fetch of galleries failed.'
                    }),
                    type: 'error'
                });
            } else if (props.errorDraft) {
                setSnackBarStatus({
                    show: true,
                    message: intl.formatMessage({
                        id: 'showroom.loading.galleries.draft.error',
                        defaultMessage: 'Fetch of draft galleries failed.'
                    }),
                    type: 'error'
                });
            } else if (props.errorFinal) {
                setSnackBarStatus({
                    show: true,
                    message: intl.formatMessage({
                        id: 'showroom.loading.galleries.final.error',
                        defaultMessage: 'Fetch of final galleries failed.'
                    }),
                    type: 'error'
                });
            }
        } else if (!props.loadingNewGallery && props.errorNewGallery !== null) {
            console.log('NEW GALLERY: ', props.loadingNewGallery, ' - ', props.errorNewGallery);
            if (!props.errorNewGallery) {
                setSnackBarStatus({
                    show: true,
                    message: intl.formatMessage({
                        id: 'showroom.creation.new.gallery.success',
                        defaultMessage: 'Gallery has been created.'
                    }),
                    type: 'success',
                    messageAbout: 'newGallery'
                });
            } else {
                setSnackBarStatus({
                    show: true,
                    message: intl.formatMessage({
                        id: 'showroom.creation.new.gallery.failed',
                        defaultMessage: 'Gallery creation failed.'
                    }),
                    type: 'error',
                    messageAbout: 'newGallery'
                });
            }
        } else if (!props.loadingExportLookcast && props.exportActionType === 'POST') {
            console.log('EXPORT TO LOOKCAST: ', props.loadingExportLookcast, props.exportActionType, props.errorExport, props.lookcastExportResult);

            if (props.errorExport) {
                setSnackBarStatus({
                    show: true,
                    message: intl.formatMessage({
                        id: 'showroom.exporting.gallery.to.lookcast.failed',
                        defaultMessage: 'Cannot export gallery to Lookcast.'
                    }),
                    type: 'error',
                    messageAbout: 'exportLookcast'
                });
            } else if (props.lookcastExportResult) {
                setSnackBarStatus({
                    show: true,
                    message: intl.formatMessage({
                        id: 'showroom.exporting.gallery.to.lookcast.success',
                        defaultMessage: 'Gallery has been exported to Lookcast with success.'
                    }),
                    type: 'success',
                    messageAbout: 'exportLookcast'
                });
            }
        } else if (!props.showroomGalleryDownloads.loading && props.showroomGalleryDownloads.actionType === 'GET' && props.showroomGalleryDownloads.error) {
            console.log('DOWNLOAD GALLERY EXCEL: ', props.showroomGalleryDownloads.loading, props.showroomGalleryDownloads.actionType, props.showroomGalleryDownloads.error);
            setSnackBarStatus({
                show: true,
                message: intl.formatMessage({
                    id: 'showroom.creating.gallery.excel.error',
                    defaultMessage: 'Cannot export create gallery\'s excel.'
                }),
                type: 'error',
                messageAbout: 'exportGalleryExcel'
            });
        } else if (!props.showroomGalleryDownloads.loading && props.showroomGalleryDownloads.actionType === 'POST' && props.showroomGalleryDownloads.error) {
            console.log('DOWNLOAD GALLERY ASSETS: ', props.showroomGalleryDownloads.loading, props.showroomGalleryDownloads.actionType, props.showroomGalleryDownloads.error);
            setSnackBarStatus({
                show: true,
                message: intl.formatMessage({
                    id: 'showroom.downloading.gallery.zip.error',
                    defaultMessage: 'Cannot export gallery\'s assets.'
                }),
                type: 'error',
                messageAbout: 'exportGalleryZip'
            });
        }
    }, [
        props.loadingShowroomGalleries, props.errorGalleries, props.errorFinal, props.errorDraft,           // Fetching new galleries
        props.loadingNewGallery, props.errorNewGallery,                                                     // Creating new gallery
        props.loadingExportLookcast, props.exportActionType, props.errorExport, props.lookcastExportResult, // Export gallery to lookcast
        props.showroomGalleryDownloads                                                                      // Download gallery excel
    ]);

    /**
     * Handler of message during uploading of assets.
     */
    useEffect(() => {
        if (uploadState) {
            if (!uploadState.loadingUploadShowroom && uploadState.finalizedError) {
                // error during finalized
                setSnackBarStatus({
                    show: true,
                    message: intl.formatMessage({
                        id: 'showroom.error.asset.infos.save',
                        defaultMessage: 'We cannot saving asset\'s infos.'
                    }),
                    type: 'error'
                })
            } else if (!uploadState.loadingUploadShowroom && uploadState.numberError > 0) {
                // error during uploading physically files
                uploadState.totalAssets === uploadState.numberError ?
                    setSnackBarStatus({
                        show: true,
                        message: intl.formatMessage({
                            id: 'showroom.error.physical.assets.upload',
                            defaultMessage: 'We cannot upload assets to the cloud.'
                        }),
                        type: 'error'
                    }) :
                    setSnackBarStatus({
                        show: true,
                        message: intl.formatMessage({
                            id: 'showroom.warning.assets.infos.save',
                            defaultMessage: 'Probably some assets are corrupted and we cannot upload them to the cloud: {numberError}'
                        }, { numberError: uploadState.numberError }),
                        type: 'warning'
                    })
            } else if (!uploadState.loadingUploadShowroom && uploadState.totalAssets > 0) {
                setSnackBarStatus({
                    show: true,
                    message: intl.formatMessage({
                        id: 'showroom.success.assets.upload',
                        defaultMessage: 'Upload completed. Total upload: {totalAssetsUploaded}'
                    }, { totalAssetsUploaded: uploadState.totalAssets }),
                    type: 'success'
                });
                setDropzoneDialogOpen(false);
            }
        }
    }, [
        uploadState.finalizedError, uploadState.loadingUploadShowroom,      // Uploading assets
    ]);

    /**
     * Handler of error message during fetching of context elements and context id.
     */
    useEffect(() => {
        if (!props.loadingContext && props.errorContext) {          // error during fetching contextId
            console.log('CONTEXT: ', props.loadingContext, ' - ', props.errorContext);
            setSnackBarStatus({
                show: true,
                message: intl.formatMessage({
                    id: 'showroom.loading.context.error',
                    defaultMessage: 'Fetch of context failed.'
                }),
                type: 'error'
            });
        } else if (!props.loadingRichiedente && props.errorRichiedente) {
            console.log('RICHIEDENTE: ', props.loadingRichiedente, ' - ', props.errorRichiedente);
            setSnackBarStatus({
                show: true,
                message: intl.formatMessage({
                    id: 'showroom.loading.richiedente.error',
                    defaultMessage: 'Fetch of applicant failed.'
                }),
                type: 'error'
            });
        } else if (!props.loadingSeason && props.errorSeason) {
            console.log('SEASON: ', props.loadingSeason, ' - ', props.errorSeason);
            setSnackBarStatus({
                show: true,
                message: intl.formatMessage({
                    id: 'showroom.loading.seasons.error',
                    defaultMessage: 'Fetch of seasons failed.'
                }),
                type: 'error'
            });
        } else if (!props.loadingBrand && props.errorBrand) {
            console.log('BRAND: ', props.loadingBrand, ' - ', props.errorBrand);
            setSnackBarStatus({
                show: true,
                message: intl.formatMessage({
                    id: 'showroom.loading.brands.error',
                    defaultMessage: 'Fetch of brands failed.'
                }),
                type: 'error'
            });
        } else if (!props.loadingGender && props.errorGender) {
            console.log('GENDER: ', props.loadingGender, ' - ', props.errorGender);
            setSnackBarStatus({
                show: true,
                message: intl.formatMessage({
                    id: 'showroom.loading.genders.error',
                    defaultMessage: 'Fetch of genders failed.'
                }),
                type: 'error'
            });
        } else if (!props.loadingEvents && props.errorEvents) {
            console.log('EVENTS: ', props.loadingEvents, ' - ', props.errorEvents);
            setSnackBarStatus({
                show: true,
                message: intl.formatMessage({
                    id: 'showroom.loading.events.error',
                    defaultMessage: 'Fetch of events failed.'
                }),
                type: 'error'
            });
        }
    }, [
        props.loadingContext, props.errorContext,               // possible error during getting of contextId
        props.loadingRichiedente, props.errorRichiedente,       // possible error during fetching of richiedenti
        props.loadingSeason, props.errorSeason,                 // possible error during fetching of seasons
        props.loadingBrand, props.errorBrand,                   // possible error during fetching of brands
        props.loadingGender, props.errorGender,                 // possible error during fetching of genders
        props.loadingEvents, props.errorEvents                  // possible error during fetching of events
    ]);

    /**
     * Handler of error message about looks.
     */
    useEffect(() => {
        if (!props.loadingLooks && props.errorLooks !== null) {
            console.log('LOOKS: ', props.loadingLooks, ' - ', props.errorLooks);
            setSnackBarStatus({
                show: true,
                message: intl.formatMessage({
                    id: 'showroom.loading.looks.error',
                    defaultMessage: 'Fetch of looks failed.'
                }),
                type: 'error',
                messageAbout: 'loadingLooks'
            });
        } else if (!props.loadingLook && props.typeActionLook === 'PUT') {
            console.log('LOOK: ', props.loadingLook, ' - ', props.errorLook);
            if (props.errorLook !== null) {
                setSnackBarStatus({
                    show: true,
                    message: intl.formatMessage({
                        id: 'showroom.updating.look.error',
                        defaultMessage: 'Update of look failed.'
                    }),
                    type: 'error',
                    messageAbout: 'updateLook'
                });
            } else {
                setSnackBarStatus({
                    show: true,
                    message: intl.formatMessage({
                        id: 'showroom.updating.look.success',
                        defaultMessage: 'Look updated successfully.'
                    }),
                    type: 'success',
                    messageAbout: 'updateLook'
                });
            }
        } else if (!props.loadingLook && props.typeActionLook === 'POST') {
            console.log('LOOK: ', props.loadingLook, ' - ', props.errorLook);
            if (props.errorLook !== null) {
                setSnackBarStatus({
                    show: true,
                    message: intl.formatMessage({
                        id: 'showroom.creating.look.error',
                        defaultMessage: 'Creation of look failed.'
                    }),
                    type: 'error',
                    messageAbout: 'createLook'
                });
            } else {
                setSnackBarStatus({
                    show: true,
                    message: intl.formatMessage({
                        id: 'showroom.creating.look.success',
                        defaultMessage: 'Look created successfully.'
                    }),
                    type: 'success',
                    messageAbout: 'createLook'
                });
            }
        } else if (!props.loadingLook && props.typeActionLook === 'DELETE') {
            console.log('LOOK: ', props.loadingLook, ' - ', props.errorLook);
            if (props.errorLook !== null) {
                setSnackBarStatus({
                    show: true,
                    message: intl.formatMessage({
                        id: 'showroom.deleting.look.failed',
                        defaultMessage: 'Cannot remove this look.'
                    }),
                    type: 'error',
                    messageAbout: 'deleteLook'
                });
            } else {
                setSnackBarStatus({
                    show: true,
                    message: intl.formatMessage({
                        id: 'showroom.deleting.look.success',
                        defaultMessage: 'Look has been deleted with success.'
                    }),
                    type: 'success',
                    messageAbout: 'deleteLook'
                });
            }
        } else if (!props.loadingLookProduct && props.typeLookProduct === 'PUT-ORDER') {
            console.log('PRD ORDER: ', props.loadingLookProduct, ' - ', props.errorLookProduct, ' - ', props.typeLookProduct);

            if (props.errorLookProduct !== null) {
                setSnackBarStatus({
                    show: true,
                    message: intl.formatMessage({
                        id: 'showroom.updating.look.products.order.failed',
                        defaultMessage: 'Sorting of products failed.'
                    }),
                    type: 'error',
                    messageAbout: 'lookProductsOrder'
                });
            } else {
                setSnackBarStatus({
                    show: true,
                    message: intl.formatMessage({
                        id: 'showroom.updating.look.products.order.success',
                        defaultMessage: 'Sorting has been completed.'
                    }),
                    type: 'success',
                    messageAbout: 'lookProductsOrder'
                });
            }
        } else if (!props.loadingLookProduct && props.typeLookProduct === 'DELETE') {
            console.log('PRD DELETE: ', props.loadingLookProduct, ' - ', props.errorLookProduct, ' - ', props.typeLookProduct);

            if (props.errorLookProduct !== null) {
                setSnackBarStatus({
                    show: true,
                    message: intl.formatMessage({
                        id: 'showroom.deleting.look.product.failed',
                        defaultMessage: 'Cannot remove this product from this look.'
                    }),
                    type: 'error',
                    messageAbout: 'deleteProduct'
                });
            } else {
                setSnackBarStatus({
                    show: true,
                    message: intl.formatMessage({
                        id: 'showroom.deleting.look.product.success',
                        defaultMessage: 'Product has been remove with success from look selected.'
                    }),
                    type: 'success',
                    messageAbout: 'deleteProduct'
                });
            }
        } else if (!props.loadingLookProduct && props.typeLookProduct === 'POST') {
            console.log('PRD ADD: ', props.loadingLookProduct, ' - ', props.errorLookProduct, ' - ', props.typeLookProduct);

            if (props.errorLookProduct !== null) {
                setSnackBarStatus({
                    show: true,
                    message: intl.formatMessage({
                        id: 'showroom.adding.look.product.failed',
                        defaultMessage: 'Cannot add the product selected to the current look.'
                    }),
                    type: 'error',
                    messageAbout: 'addProduct'
                });
            } else if(props.productLookPlaceholder){
                setSnackBarStatus({
                    show: true,
                    message: intl.formatMessage({
                        id: 'showroom.adding.look.product.placeholder',
                        defaultMessage: 'Retrieved Product Placeholder from PIM'
                    }),
                    type: 'warning',
                    messageAbout: ''
                });
            }
            else {
                setSnackBarStatus({
                    show: true,
                    message: intl.formatMessage({
                        id: 'showroom.adding.look.product.success',
                        defaultMessage: 'Product has been added with success to the current look.'
                    }),
                    type: 'success',
                    messageAbout: 'addProduct'
                });
            }
        } else if (!props.loadingLookProduct && props.typeLookProduct === 'PUT') {
            console.log('PRD UPDATE: ', props.loadingLookProduct, ' - ', props.errorLookProduct, ' - ', props.typeLookProduct);

            if (props.errorLookProduct !== null) {
                setSnackBarStatus({
                    show: true,
                    message: intl.formatMessage({
                        id: 'showroom.updating.look.product.failed',
                        defaultMessage: 'Cannot update this product.'
                    }),
                    type: 'error',
                    messageAbout: 'updateProduct'
                });
            } else {
                setSnackBarStatus({
                    show: true,
                    message: intl.formatMessage({
                        id: 'showroom.updating.look.product.success',
                        defaultMessage: 'Product has been updated.'
                    }),
                    type: 'success',
                    messageAbout: 'updateProduct'
                });
            }
        }
    }, [
        props.loadingLooks, props.errorLooks,                                       // fetching looks
        props.loadingLook, props.errorLook, props.typeActionLook,                   // create/update look
        props.loadingLookProduct, props.errorLookProduct, props.typeLookProduct, props.productLookPlaceholder  // update look products order/remove product from look
    ]);

    /**
     * Handle success/error for cancelling of digitalAssets by a gallery.
     */
    useEffect(() => {
        console.log('Ale: \n- loading: ', props.loadingAsset, '\n- error: ', props.errorUpdateAsset, '\n- action: ', props.assetActionType, '\n- updatedAsset: ', props.updatedAsset);
        if (!props.loadingAsset && props.assetActionType === 'PUT') {
            if (props.errorUpdateAsset) {
                setSnackBarStatus({
                    show: true,
                    message: intl.formatMessage({
                        id: 'showroom.updating.asset.information.failed',
                        defaultMessage: 'Update of asset\'s information failed.'
                    }),
                    type: 'error',
                    messageAbout: 'updateAsset'
                });
            } else if (!_.isEmpty(props.updatedAsset)) {
                setSnackBarStatus({
                    show: true,
                    message: intl.formatMessage({
                        id: 'showroom.updating.asset.information.success',
                        defaultMessage: 'Asset\'s information have been updated.'
                    }),
                    type: 'success',
                    messageAbout: 'updateAsset'
                });
            }
        } else if (!props.loadingAsset && props.assetActionType === 'DELETE') {
            if (props.errorUpdateAsset) {
                setSnackBarStatus({
                    show: true,
                    message: intl.formatMessage({
                        id: 'showroom.deleting.asset.physically.failed',
                        defaultMessage: 'Cannot delete asset.'
                    }),
                    type: 'error',
                    messageAbout: 'deleteAsset'
                });
            } else {
                setSnackBarStatus({
                    show: true,
                    message: intl.formatMessage({
                        id: 'showroom.deleting.asset.physically.success',
                        defaultMessage: 'Asset has been deleted.'
                    }),
                    type: 'success',
                    messageAbout: 'deleteAsset'
                });
            }
        }
    }, [props.loadingAsset, props.errorUpdateAsset, props.updatedAsset, props.assetActionType]);

    /**
     * Handler to close snackbar message and clear redux state to prevent the rendering of the same message.
     */
    const closeSnackbar = () => {
        switch (snackBarStatus.messageAbout) {
            case 'newGallery':
                props.clearNewGallery();
                break;
            case 'loadingLooks':
                props.clearLooks();
                break;
            case 'updateLook':
            case 'createLook':
            case 'deleteLook':
                props.clearLook();
                break;
            case 'deleteAsset':
            case 'updateAsset':
                props.clearUpdatedAsset();
                break;
            case 'lookProductsOrder':
            case 'addProduct':
            case 'updateProduct':
            case 'deleteProduct':
                props.clearLookOrder();
                break;
            case 'exportLookcast':
                props.clearExportLookcast();
                break;
            case 'exportGalleryExcel':
            case 'exportGalleryZip':
                props.clearDownloadGallery();
                break;
            default:
                break;
        }

        setSnackBarStatus({
            show: false,
            message: '',
            type: '',
            messageAbout: ''
        })
    };

    /* **************************************************************************************************** */


    /* ************************************* HANDLER TO SHOW BACKDROP ********************************************** */
    /**
     *  Handler to show backdrop when digitalAssets are uploaded or their order into a gallery change.
     */
    useEffect(() => {
        if (uploadState && uploadState.loadingUploadShowroom && uploadState.totalAssets > 0) {
            setBackdropState((prevState => ({
                ...prevState,
                loading: true,
                label: `${uploadState.processed}/${uploadState.totalAssets} completed.`
            })));
        } else if (props.loadingAssetsOrderGallery || props.loadingAsset) {
            setBackdropState((prevState => ({
                ...prevState,
                loading: true,
                label: ''
            })));
        } else {
            setBackdropState((prevState => ({
                ...prevState,
                loading: false,
                label: ''
            })));
        }
    }, [
        uploadState.loadingUploadShowroom,
        uploadState.processed,
        uploadState.totalAssets,
        props.loadingAssetsOrderGallery,
        props.loadingAsset
    ]);

    /**
     *  Handler to show backdrop when something change for looks.
     */
    useEffect(() => {
        if (props.loadingShowroomGalleries) {
            setBackdropState((prevState => ({
                ...prevState,
                loading: true,
                label: ''
            })));
        } else if (props.loadingLooks) {
            setBackdropState((prevState => ({
                ...prevState,
                loading: true,
                label: ''
            })));
        } else if (props.loadingLookProduct) {
            setBackdropState((prevState => ({
                ...prevState,
                loading: true,
                label: ''
            })));
        } else {
            setBackdropState((prevState => ({
                ...prevState,
                loading: false,
                label: ''
            })));
        }
    }, [
        props.loadingShowroomGalleries,
        props.loadingLooks,
        props.loadingLookProduct
    ]);

    /* **************************************************************************************************** */


    /* ****************************************** UPLOAD NEW DIGITAL ASSETS **************************************** */
    const saveAssetsLocally = (files) => {
        let newFiles = _.cloneDeep(files);
        newFiles.forEach(asset => {
            console.log('ALE3: ', asset);
            asset.label = asset.name.split('.').slice(0, -1).join('.');
            let reader = new FileReader();
            reader.readAsDataURL(asset);
            reader.onload = () => {
                asset.img_url = reader.result;
            }
        });

        setSnackBarStatus({
            show: true,
            message: files.length + intl.formatMessage({
                id: 'common.file.selected',
                defaultMessage: ' files selected.'
            }),
            type: 'success'
        });
    };

    const onSubmitAssets = (files) => {
        console.log(files);
        if (uploadState.totalAssets > 0) {
            props.clearUpload();
        }
        props.uploadDigitalAssetsFile(files);
    };

    /**
     * This effect is used to update the state of uploaded assets and notify the conclusion of assets upload on bucket
     * of cloud storage (physical upload of files).
     */
    useEffect(() => {
        if (props.uploadedAssets) {
            console.log('Stato asset caricati fino ad ora: ', props.uploadedAssets);
            setUploadState(_.cloneDeep(props.uploadedAssets));

            if (props.uploadedAssets.totalAssets !== 0 &&
                !props.uploadedAssets.physicalUploadComplete &&
                props.uploadedAssets.processed === props.uploadedAssets.totalAssets) {
                props.setPhysicalUploadComplete();
            }
        }
    }, [props.uploadedAssets])


    /**
     * After loaded digital assets (photos or videos) on bucket we have to finalized infos about them into database.
     */
    useEffect(() => {
        if (uploadState && uploadState.physicalUploadComplete && draftStatus !== '' && props.showroomContext.id) {
            const filter = getAppliedFilter(viewType);
            props.finalizedUpload(draftStatus, uploadState.uploadedAssets, props.showroomContext.id, filter);
        }
    }, [uploadState.physicalUploadComplete]);
    /* ************************************************************************************************************** */


    /* ************************************************ CONTEXT **************************************************** */
    const onChangeContextDropdownHandler = event => {
        let tmpContextStatus = _.cloneDeep(contextStatus);

        switch (event.target.name) {
            case ('richiedente'):
                console.log('Richiedente');
                props.getServicesByRichiedente(event.target.value);
                tmpContextStatus.richiedente = event.target.value;
                break;
            case ('service'):
                console.log('Service ', props.service);
                const serviceObj = props.service.find(obj => {
                    return obj.value === event.target.value
                });

                tmpContextStatus.service = event.target.value;
                tmpContextStatus.richiedenteService = serviceObj.richiedenteService;
                console.log('Richiedente-service: ', tmpContextStatus.richiedenteService);
                break;
            case ('season'):
                console.log('Season');
                tmpContextStatus.season = event.target.value;
                break;
            case ('brand'):
                console.log('Brand');
                tmpContextStatus.brand = event.target.value;
                break;
            case ('gender'):
                console.log('Gender');
                tmpContextStatus.gender = event.target.value;
                break;
            case ('event'):
                console.log('Event');
                tmpContextStatus.event = event.target.value;
                break;
            default:
                console.log('Unknown dropdown!');
                break;
        }
        setContextStatus(tmpContextStatus);
    };

    const onChangeDraftDropdownHandler = event => {
        setDraftStatus(event.target.value);
    };

    const groupElements = [
        {
            type: "dropdown",
            name: "richiedente",
            value: contextStatus['richiedente'],
            onChangeHandler: onChangeContextDropdownHandler,
            icon: "person",
            label: { translateKey: "common.dropdown.label.richiedente", defaultText: "Richiedente" },
            options: props.richiedente || [],
            loading: props.loadingRichiedente,
        },
        {
            type: "dropdown",
            name: "service",
            value: contextStatus['service'],
            onChangeHandler: onChangeContextDropdownHandler,
            icon: "settings",
            label: { translateKey: "common.dropdown.label.service", defaultText: "Service" },
            options: props.service || [],
            loading: props.loadingService,
            disabled: contextStatus.richiedente === ''
        },
        {
            type: "dropdown",
            name: "season",
            value: contextStatus['season'],
            onChangeHandler: onChangeContextDropdownHandler,
            icon: "miscellaneous_services",
            label: { translateKey: "common.dropdown.label.season", defaultText: "Season" },
            options: props.seasons || [],
            loading: props.loadingSeason
        },
        {
            type: "dropdown",
            name: "brand",
            value: contextStatus['brand'],
            onChangeHandler: onChangeContextDropdownHandler,
            icon: "miscellaneous_services",
            label: { translateKey: "common.dropdown.label.brand", defaultText: "Brand" },
            options: props.brand || [],
            loading: props.loadingBrand
        },
        {
            type: "dropdown",
            name: "gender",
            value: contextStatus['gender'],
            onChangeHandler: onChangeContextDropdownHandler,
            icon: "miscellaneous_services",
            label: { translateKey: "common.dropdown.label.gender", defaultText: "Gender" },
            options: props.gender || [],
            loading: props.loadingGender
        },
        {
            type: "dropdown",
            name: "event",
            value: contextStatus['event'],
            onChangeHandler: onChangeContextDropdownHandler,
            icon: "miscellaneous_services",
            label: { translateKey: "showroom.dropdown.event", defaultText: "Event" },
            options: props.events || [],
            loading: props.loadingEvents
        }
    ];

    const contextElements = groupElements.map((element, index) =>
        <Grid item xs={6} key={index}>
            <DropDownElement
                key={index}
                name={element.name}
                value={element.value}
                onChangeHandler={element.onChangeHandler}
                icon={element.icon}
                label={element.label}
                options={element.options}
                disabled={element.disabled}
                loading={element.loading}/>
        </Grid>
    );

    const contextAccordionOnChangeHandler = event => {
        setContextAccordionOpenState(prevState => !prevState);
    };
    /* ************************************************************************************************************** */


    const menuTabs = [
        { translateKey: 'showroom.tab.looks', default: 'Look' },
        /*{ translateKey: 'showroom.tab.draft', default: 'Gallery (Draft)' },
        { translateKey: 'showroom.tab.final', default: 'Gallery (Final)' },*/
        { translateKey: 'showroom.tab.all', default: 'Gallery' }
    ];

    const viewTabsHandler = (event, newValue) => {
        setViewType(newValue);
    };


    return (
        <div className={classes.root}>
            <BackdropElement loading={backdropState.loading} label={backdropState.label}/>
            <Grid container
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-start"
                  spacing={4}>
                <Grid item xs={12}>
                    <AccordionElement id={'showroom-context-menu'}
                                      header={'Context'}
                                      expanded={contextAccordionOpenState}
                                      onChangeHandler={contextAccordionOnChangeHandler}>
                        <Grid container
                              direction='row'
                              alignItems='center'
                              justify='space-around'
                              spacing={3}>
                            <Grid item xs={6}>
                                <Grid container
                                      direction='row'
                                      alignItems='center'
                                      justify='center'
                                      spacing={3}>
                                    {contextElements}
                                </Grid>
                            </Grid>
                            <Grid item xs={4}/>
                            <Grid item xs={2}>
                                <Grid container
                                      direction='column'
                                      spacing={2}>
                                    <Grid item className={classes.uploadAssetLabel}>
                                        <Typography>{intl.formatMessage({
                                            id: 'showroom.label.upload.assets',
                                            defaultMessage: 'Upload assets'
                                        })}:</Typography>
                                    </Grid>
                                    <Grid item>
                                        <DropDownElement name={'is-draft'}
                                                         value={draftStatus}
                                                         onChangeHandler={onChangeDraftDropdownHandler}
                                                         icon={'miscellaneous_services'}
                                                         label={{
                                                             translateKey: 'showroom.dropdown.draft',
                                                             defaultText: 'Draft/Final'
                                                         }}
                                                         options={draftOptions}/>
                                    </Grid>
                                    <Grid item>
                                        <ButtonElement
                                            labelId={'common.button.label.upload.assets'}
                                            label={'Upload Assets'}
                                            onSubmit={() => setDropzoneDialogOpen(true)}
                                            disabled={disabledUploadStatus}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionElement>
                </Grid>
                <Grid item xs={12}>
                    <MenuTabsElement
                        value={viewType}
                        onChangeHandler={viewTabsHandler}
                        tabs={menuTabs}/>
                </Grid>
                {viewType === 0 &&
                <Grid item xs={12}>
                    <LooksContainer contextId={props.showroomContext.id || ""}/>
                </Grid>
                }
                {viewType === 1 &&
                <Grid item xs={12}>
                    <GalleriesContainer contextId={props.showroomContext.id || ""} filter={getAppliedFilter(1)}/>
                </Grid>
                }
                {viewType === 2 &&
                <Grid item xs={12}>
                    <GalleriesContainer contextId={props.showroomContext.id || ""} filter={getAppliedFilter(2)}/>
                </Grid>
                }
                {viewType === 3 &&
                <Grid item xs={12}>
                    <GalleriesContainer contextId={props.showroomContext.id || ""} filter={getAppliedFilter(3)}/>
                </Grid>
                }
            </Grid>
            <DropzoneDialogElement
                acceptedFiles={['image/*', 'video/mp4', 'video/mov']}
                dialogTitle={{ labelId: "upload.digital.assets.dropzone.dialog.title", label: "Upload Digital Assets" }}
                filesLimit={300}
                maxFileSize={50000000}
                saveFileHandler={onSubmitAssets}
                onDropHandler={saveAssetsLocally}
                dropzoneDialogOpen={dropzoneDialogOpen}
                setDropzoneDialogOpen={setDropzoneDialogOpen}
                cancelButtonText={{ labelId: "common.button.label.cancel", label: "Cancel" }}
                submitButtonText={{ labelId: "common.button.label.submit", label: "Submit" }}
                showPreviews={true}
                chipPreview={true}
            />
            {snackBarStatus.show &&
            <SnackbarElement open={snackBarStatus.show}
                             type={snackBarStatus.type}
                             label={snackBarStatus.message}
                             labelParams={snackBarStatus.values}
                             handleClose={() => closeSnackbar()}
                             showDuration={7200000}
            />}
        </div>
    )
};

const mapStateToProps = state => ({
    auth: state.authReducer.auth,
    loadingRichiedente: state.richiedenteReducer.loadingRichiedente,
    loadingService: state.serviceReducer.loadingServices,
    loadingSeason: state.seasonReducer.loadingSeason,
    loadingBrand: state.brandReducer.loadingBrand,
    loadingGender: state.genderReducer.loadingGender,
    loadingEvents: state.eventsReducer.loadingEvents,
    loadingContext: state.contextShowroomReducer.loadingContext,
    loadingAssetsOrderGallery: state.galleriesCompositionShowroomReducer.loadingAssetsOrder,
    loadingShowroomGalleries: state.galleriesCompositionShowroomReducer.loadingShowroomGalleries,
    loadingLooks: state.looksReducer.loadingLooks,
    loadingLook: state.lookReducer.loadingLook,
    loadingNewGallery: state.galleriesCompositionShowroomReducer.loadingNewGallery,
    loadingAsset: state.contextShowroomReducer.loadingAsset,
    loadingLookProduct: state.lookReducer.loadingLookProduct,
    loadingExport: state.lookcastExportReducer.loadingExportLookcast,
    richiedente: _.toArray(_.mapValues(state.richiedenteReducer.richiedente, function (o) {
        return {
            label: o.name,
            value: o.guid,
        };
    })),
    service: _.toArray(_.mapValues(state.serviceReducer.services, function (o) {
        return {
            label: o.service,
            value: o.service_guid,
            richiedenteService: o.richiedente_service_guid
        };
    })),
    seasons: _.toArray(_.mapValues(filterActiveSeasons(state.seasonReducer.seasons), function (o) {
        return {
            label: o.name,
            value: o.guid,
        };
    })),
    brand: _.toArray(_.mapValues(state.brandReducer.brand, function (o) {
        return ({
            label: o.name,
            value: o.guid
        })
    })),
    gender: _.toArray(_.mapValues(state.genderReducer.gender, function (o) {
        return ({
            label: o.name,
            value: o.guid
        })
    })),
    events: _.toArray(_.mapValues(state.eventsReducer.events, function (o) {
        return ({
            label: o.name,
            value: o.id
        })
    })),
    showroomContext: state.contextShowroomReducer.showroomContext,
    showroomDefaultContext: state.contextShowroomReducer.defaultContext,
    uploadedAssets: state.uploadAssetsCompositionShowroomReducer,
    errorContext: state.contextShowroomReducer.error,
    errorRichiedente: state.richiedenteReducer.error,
    errorSeason: state.seasonReducer.error,
    errorBrand: state.brandReducer.error,
    errorGender: state.genderReducer.error,
    errorEvents: state.eventsReducer.error,
    errorGalleries: state.galleriesCompositionShowroomReducer.errorGalleries,
    errorDraft: state.galleriesCompositionShowroomReducer.errorDraft,
    errorFinal: state.galleriesCompositionShowroomReducer.errorFinal,
    errorNewGallery: state.galleriesCompositionShowroomReducer.errorNewGallery,
    errorLooks: state.looksReducer.errorLooks,
    errorLook: state.lookReducer.errorLook,
    errorUpdateAsset: state.contextShowroomReducer.errorAsset,
    errorLookProduct: state.lookReducer.errorLookProduct,
    errorExport: state.lookcastExportReducer.error,
    statusUpdateOrder: state.galleriesCompositionShowroomReducer.statusUpdateOrder,
    updatedAsset: state.contextShowroomReducer.updatedAsset,
    typeActionLook: state.lookReducer.type,
    assetActionType: state.contextShowroomReducer.typeActionAsset,
    exportActionType: state.lookcastExportReducer.exportActionType,
    typeLookProduct: state.lookReducer.typeLookProduct,
    lookcastExportResult: state.lookcastExportReducer.exportResult,
    showroomGalleryDownloads: state.showroomDownloadsReducer,
    productLookPlaceholder: state.lookReducer.productLookPlaceholder
});

const mapDispatchToProps = dispatch => ({
    getRichiedente: () => dispatch(startGetRichiedente()),
    getServicesByRichiedente: (richiedente) => dispatch(startGetServiceByRichiedenteFull(richiedente)),
    getSeason: () => dispatch(startGetSeasons()),
    getBrand: () => dispatch(startGetBrand()),
    getGender: () => dispatch(startGetGender()),
    getEvents: () => dispatch(startGetEvent()),
    getContext: (richiedenteService, season, brand, gender, event) => dispatch(startGetCompositionContext(richiedenteService, season, brand, gender, event)),
    getDefaultContext: () => dispatch(startGetShowroomDefaultContext()),
    uploadDigitalAssetsFile: (digitalAssets) => dispatch(startUploadShowroomAssets(digitalAssets)),
    setPhysicalUploadComplete: () => dispatch(physicalUploadComplete()),
    finalizedUpload: (isPolaroid, digitalAssets, contextId, filter) => dispatch(loadAssets(isPolaroid, digitalAssets, contextId, filter)),
    clearUpload: () => dispatch(clearUploadShowroomAssets()),
    clearGalleries: () => dispatch(clearShowroomGalleries()),
    clearNewGallery: () => dispatch(clearNewGallery()),
    clearContext: () => dispatch(clearContext()),
    clearLooks: () => dispatch(clearLooks()),
    clearLook: () => dispatch(clearLook()),
    clearUpdatedAsset: () => dispatch(clearUpdateAsset()),
    clearLookOrder: () => dispatch(clearProductLook()),
    clearExportLookcast: () => dispatch(clearLookcastExport()),
    clearDownloadGallery: () => dispatch(clearGalleryDownloadInfo()),
    updateDefaultContext: (context) => dispatch(updateShowroomDefaultContext(context))
});

export default connect(mapStateToProps, mapDispatchToProps)(CompositionShowroomContainer);