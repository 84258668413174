import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import _ from "lodash";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Look from "./LooksComponents/Look";
import { connect } from "react-redux";
import {
    clearLook,
    clearProductLook,
    clearCurrentLookDetails,
    startAddProduct,
    startDeleteLook,
    startDeleteProduct,
    startGetCurrentLookDetails,
    startPostLook,
    startPutLook,
    startUpdateProduct
} from "../../../store/actions/showroom/composition/look";
import InputTextElement from "../../../components/InputTextElement/InputTextElement";
import ReusableModal from "../../../components/Modal/ReusableModal/ReusableModal";
import IconButtonElement from "../../../components/IconButtonElement/IconButtonElement";
import DndLookShowroomProductsElement
    from "../../../components/DragNDrop/DndLookShowroomProductsElement/DndLookShowroomProductsElement";
import NewProduct from "./LooksComponents/NewProduct";
import { startClearFabricByStyle, startGetFabricByStyle } from "../../../store/actions/fabric";
import { startClearColorFromSF, startGetColorsFromSF } from "../../../store/actions/color";
import { clearLooks, startGetLooks } from "../../../store/actions/showroom/composition/looks";
import SnackbarElement from "../../../components/SnackbarElement/SnackbarElement";
import { startClearProductAll, startGetProductAllS_F_C } from "../../../store/actions/viewManagement/productAll";
import { checkAction } from "../../../utils/CommonFunction";
import { Typography, Tabs, Tab, Box, Radio, RadioGroup, FormControlLabel, FormControl } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    container: {
        overflowY: "auto",
        overflowX: "hidden",
        maxHeight: '680px',
        minHeight: '680px',
        flexDirection: 'row',
        alignContent: 'start'
    },
    buttonContainerList: {
        marginBottom: '15px'
    },
    newProductsContainer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2),
        marginTop: '45px',
        borderRadius: '4px'
    },
    productsContainer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2),
        marginTop: '45px',
        borderRadius: '4px',
        height: '640px',
        overflowY: "auto",
        overflowX: "hidden"
    },
    lookProductsContainer: {
        marginTop: '45px',
        minHeight: '680px',
        maxHeight: '680px',
        overflowY: "auto",
        overflowX: "hidden",
        flexDirection: 'row',
        alignContent: 'start'
    }
}));

const LooksContainer = props => {
    const classes = useStyles();
    const intl = useIntl();
    const { contextId } = props;

    // Lista looks
    const [looksState, setLooksState] = useState([]);
    const [expandedLookStatus, setExpandedLookStatus] = useState(null);
    const [lookDialogState, setLookDialogState] = useState({
        open: false,
        title: intl.formatMessage({ id: 'showroom.dialog.title.new.look', defaultMessage: 'Add new look' }),
        lookName: '',
        lookNote: '',
        lookId: null,
        new: null,
        isMultiple: false,
        from: 1,
        to: 1
    });
    const [productDialogState, setProductDialogState] = useState({
        open: false,
        title: intl.formatMessage({ id: 'showroom.dialog.title.product', defaultMessage: 'Product note' }),
        productNote: '',
        productMustBuy: null,
        productStyleFabricColor: null,
        productLookId: null,
        productPlaceholder: null
    })
    const [newProductState, setNewProductState] = useState({
        barcode: '',
        style: '',
        fabric: '',
        color: '',
        styleFabricColor: '',
        fakeProduct: '',
        whatChange: null,
        fromBarcode: false
    });
    const [dropdownOpenState, setDropdownOpenState] = useState({ fabric: false, color: false });
    const [snackBarStatus, setSnackBarStatus] = useState({ show: false, message: '', type: '', messageAbout: '' });
    const [deleteDialogState, setDeleteDialogState] = useState({
        open: false,
        title: '',
        body: '',
        item: null,
        onConfirm: null
    });
    const [valueNewLookTab, setValueNewLookTab] = useState("single");

    /**
     * Initial useEffect used to get all looks every time that contextId change.
     */
    useEffect(() => {
        if (contextId) {
            props.getLooks(contextId);
        }
    }, [contextId]);


    /**
     * Effect used to clear all redux status about looks;
     */
    useEffect(() => {
        // Unmount of LooksContainer
        return (() => {
            props.clearLooks();
            props.clearLook();
            props.clearColors();
            props.clearFabrics();
            props.clearLookOrder();
            props.clearLookDetails();
        });
    }, [])

    /**
     *  Effect used to set principal states of this container.
     */
    useEffect(() => {
        if (props.looks) {
            let newLooksList = props.looks.map(look => ({
                id: look.id,
                name: look.name,
                isApproved: look.is_approved,
                note: look.note,
                warning: look.warning
            }));

            setLooksState(newLooksList);
            setExpandedLookStatus(null);
            props.clearLookDetails();
        }
    }, [props.looks]);

    const onClickExpandAccordionHandler = (event, id) => {
        if (expandedLookStatus === id) {
            setExpandedLookStatus(null);
            props.clearLookDetails();
        } else {
            setExpandedLookStatus(id);
            props.getLookDetails(contextId, id);
        }
    };

    /** ***************************************** LOOKS COLUMN ****************************************************** */
    const openLookModalOnClick = (event, isNew, look) => {
        if (isNew) {
            setLookDialogState(prevState => ({ ...prevState, open: true, new: isNew }));
        } else {
            event.stopPropagation();
            setLookDialogState(prevState => ({
                ...prevState,
                open: true,
                new: isNew,
                lookId: look.id,
                lookName: look.name,
                lookNote: look.note ? look.note : ''
            }));
        }
    };

    const lookNameOnChangeHandler = event => {
        const newName = event.target.value;
        console.log("Look name ", newName)
        setLookDialogState(prevState => ({ ...prevState, lookName: newName }));
    };

    const lookNumberOnChangeHandler = event => {
        const newNum = event.target.value;
        console.log("Look number ", newNum)
        if (newNum > 0) {
            setLookDialogState(prevState => ({ ...prevState, to: newNum }));
        }

    };

    const lookNoteOnChangeHandler = event => {
        const newNote = event.target.value;
        setLookDialogState(prevState => ({ ...prevState, lookNote: newNote }));
    };

    /**
     * Function used to call api to edit look.
     */
    const updateLookOnClick = (event) => {

        console.log('UPDATE LOOK');
        props.updateLook(contextId, lookDialogState.lookId, {
            name: lookDialogState.lookName,
            note: lookDialogState.lookNote
        });

        setLookDialogState(prevState => ({ ...prevState, open: false, lookName: '', new: null, lookId: null,  isMultiple: false, from: 1, to: 1 }));
        setValueNewLookTab("single")
    };

    /**
     * Function used to call api to create look.
     */
    const createLookOnClick = (event) => {

        console.log('CREATE LOOK');
        props.createLook(contextId, { name: lookDialogState.lookName, note: lookDialogState.lookNote, from: 1, to: parseInt(lookDialogState.to), }, lookDialogState.isMultiple);

        setLookDialogState(prevState => ({ ...prevState, open: false, lookName: '', new: null, lookId: null, isMultiple: false, from: 1, to: 1 }));
        setValueNewLookTab("single")
    };

    const approveLookOnClickHandler = (event, lookId) => {
        event.stopPropagation();
        props.updateLook(contextId, lookId, { is_approved: true });
    };

    const resetLookOnClickHandler = (event, lookId) => {
        event.stopPropagation();
        props.updateLook(contextId, lookId, { is_approved: false });
    };

    const resetDeleteDialogState = () => {
        console.log("Reset delete dialog state");

        setDeleteDialogState({
            ...deleteDialogState,
            open: false,
            item: null,
            onConfirm: null,
            body: '',
            title: ''
        });
    }

    const deleteLook = (look) => {
        console.log('Start removing look:', look);

        if (look !== null) {
            props.removeLook(contextId, look.id);
            resetDeleteDialogState();
        }
        else {
            console.warn("Invalid look to delete");
        }
    };
    /** ************************************************************************************************************* */

    /** ***************************************** PRODUCTS COLUMN *************************************************** */

    /**
     * This useEffect is used to update looks state and relative products. In this way we don't need recall again be to
     * fetch the list of looks.
     */
    useEffect(() => {
        if (!_.isEmpty(props.productLook)) {
            console.log('Sono qui');
            console.log(props.productLook);
            let newLooksState = _.cloneDeep(looksState);

            const lookIndex = _.findIndex(newLooksState, look => look.id === props.productLook.id);

            newLooksState.splice(lookIndex, 1, _.cloneDeep(props.productLook));

            setLooksState(newLooksState);
            if (lookIndex === expandedLookStatus) {
                props.getLookDetails(contextId, expandedLookStatus);
            }
        }
    }, [props.productLook]);

    /**
     * This useEffect is used to update looks state and relative products. In this way we don't need recall again be to
     * fetch the list of looks.
     */
    useEffect(() => {
        if (!_.isEmpty(props.productLookPlaceholder)) {
            let productPlaceholder = _.cloneDeep(props.productLookPlaceholder);

            if (productPlaceholder) {
                let productPlaceholderSFC = productPlaceholder.product_sfc;
                console.log('product placeholder sfc = ' + productPlaceholderSFC);

                setNewProductState(prevState => ({
                    ...prevState,
                    barcode: '',
                    style: '',
                    fabric: '',
                    color: '',
                    styleFabricColor: '',
                    fakeProduct: productPlaceholderSFC,
                    whatChange: 'fakeStyleFabricColor',
                    fromBarcode: true
                }));

                handleSetFocus("style_fabric_color_fake");
                //document.getElementById("style_fabric_color_fake").focus();
            }
        }
    }, [props.productLookPlaceholder]);

    const removeProductFromLook = ({ lookId, product }) => {
        props.removeProduct(contextId, lookId, product);
        resetDeleteDialogState();
    };

    const mustBuyProductHandleChange = (event, lookId, product) => {
        props.updateLookProduct(contextId, lookId, product, event.target.checked);
    };

    const openProductModalOnClick = (event, lookId, product) => {
        setProductDialogState(prevState => ({
            ...prevState,
            open: true,
            productNote: product.note ? product.note : '',
            productMustBuy: product.must_buy,
            productStyleFabricColor: product.style_fabric_color,
            productPlaceholder: product.placeholder,
            productLookId: lookId
        }));
    };

    const productNoteOnChangeHandler = event => {
        const newNote = event.target.value;
        setProductDialogState(prevState => ({ ...prevState, productNote: newNote }));
    };

    const updateProductNoteOnClickHandler = (event) => {
        props.updateLookProduct(contextId, productDialogState.productLookId, {
            style_fabric_color: productDialogState.productStyleFabricColor,
            placeholder: productDialogState.productPlaceholder,
            note: productDialogState.productNote
        }, productDialogState.productMustBuy);

        setProductDialogState((prevState => ({
            ...prevState,
            open: false,
            productNote: '',
            productMustBuy: null,
            productStyleFabricColor: null,
            productLookId: null,
            productPlaceholder: null
        })));
    };
    /** ************************************************************************************************************* */

    /** ***************************************** ADD NEW PRODUCT COLUMN ******************************************** */
    const handleSetFocus = elem => {
        console.log("FOCUS ON: ", elem);
        try {
            window.setTimeout(function () {
                document.getElementById(elem).focus();
                setDropdownOpenState(prevState => ({ ...prevState, [elem]: true }))
            }, 0);
        } catch {
            console.log("Cannot read property 'focus' of null");
        }
    };

    const barcodeOnChangeHandler = event => {
        const value = event.target.value;

        setNewProductState(prevState => {
            let newWhatChange = null;

            if (prevState.whatChange === null && value !== '') {
                newWhatChange = 'barcode';
            } else if (prevState.whatChange !== null && value !== '') {
                newWhatChange = prevState.whatChange;
            }
            return {
                ...prevState,
                barcode: value,
                whatChange: newWhatChange
            }
        });
    };

    const styleOnChangeHandler = event => {
        const value = event.target.value;

        setNewProductState(prevState => {
            let newWhatChange = null;

            if (prevState.whatChange === null && value !== '') {
                newWhatChange = 'style';
            } else if (prevState.whatChange !== null && value !== '') {
                newWhatChange = prevState.whatChange;
            }
            return {
                ...prevState,
                style: value,
                fabric: '',
                color: '',
                styleFabricColor: '',
                whatChange: newWhatChange
            }
        });

        if (value === '') {
            props.clearFabrics();
            props.clearColors();
        }
    };

    const styleOnKeyPressHandler = (event) => {
        if (event.key === "Enter") {
            props.getFabrics(event.target.value);
            handleSetFocus("fabric");
        }
    };

    const fabricOnChangeHandler = event => {
        const value = event.target.value;

        props.getColors(newProductState.style, event.target.value);

        setNewProductState(prevState => ({
            ...prevState,
            fabric: value,
            color: '',
            styleFabricColor: ''
        }));
        setDropdownOpenState(prevState => ({ ...prevState, fabric: false }));
        handleSetFocus("color");
    };

    /**
     * To open automatically fabric dropdown handling possible errors.
     */
    useEffect(() => {
        if (props.fabricError) {
            setDropdownOpenState(prevState => ({ ...prevState, fabric: false }));
            setSnackBarStatus(prevState => ({
                ...prevState,
                show: true,
                message: intl.formatMessage({
                    id: 'showroom.loading.look.product.fabric.list.error',
                    defaultMessage: 'Fetching of fabric list failed.'
                }),
                type: 'error',
                messageAbout: 'fabric'
            }));
        }
    }, [props.fabricError])

    const colorOnChangeHandler = event => {
        const value = event.target.value;

        setNewProductState(prevState => ({
            ...prevState,
            color: value,
            styleFabricColor: prevState.style.concat('_', prevState.fabric, '_', value)
        }));
        setDropdownOpenState(prevState => ({ ...prevState, color: false }));
    };

    /**
     * To open automatically color dropdown handling possible errors.
     */
    useEffect(() => {
        if (props.colorError) {
            setDropdownOpenState(prevState => ({ ...prevState, color: false }));
            setSnackBarStatus(prevState => ({
                ...prevState,
                show: true,
                message: intl.formatMessage({
                    id: 'showroom.loading.look.product.color.list.error',
                    defaultMessage: 'Fetching of color list failed.'
                }),
                type: 'error',
                messageAbout: 'color'
            }));
        }

    }, [props.colorError])

    const styleFabricColorOnChangeHandler = event => {
        const value = event.target.value;

        if (value !== '') {
            props.getProductAllS_F_C(value.toUpperCase());
        } else {
            props.clearProductAll();
        }

        setNewProductState(prevState => {
            let newWhatChange = null;

            if (prevState.whatChange === null && value !== '') {
                newWhatChange = 'styleFabricColor';
            } else if (prevState.whatChange !== null && value !== '') {
                newWhatChange = prevState.whatChange;
            }

            // Style, fabric and color will be set by useEffect below;
            return {
                ...prevState,
                style: '',
                fabric: '',
                color: '',
                styleFabricColor: value,
                whatChange: newWhatChange
            }
        });
    };

    /**
     * This effect is used to set style, fabric and color values when user insert style_fabric_color code: when this
     * values is insert be is called to get that 3 values splitted in the right way.
     */
    useEffect(() => {
        if (props.productSFCInfo.loadingProductAll !== undefined && props.productSFCInfo.loadingProductAll === false &&
            !props.productSFCInfo.error && props.productSFCInfo.style && props.productSFCInfo.fabric &&
            props.productSFCInfo.fabric) {
            setNewProductState(prevState => ({
                ...prevState,
                style: props.productSFCInfo.style,
                fabric: props.productSFCInfo.fabric,
                color: props.productSFCInfo.color
            }));
        }
    }, [props.productSFCInfo])

    const fakeProductOnChangeHandler = event => {
        const value = event.target.value;

        setNewProductState(prevState => {
            let newWhatChange = null;

            console.log('Ale: ', prevState.whatChange);

            if (prevState.whatChange === null && value !== '') {
                newWhatChange = 'fakeStyleFabricColor';
            } else if (prevState.whatChange !== null && value !== '') {
                newWhatChange = prevState.whatChange;
            }

            return {
                ...prevState,
                fakeProduct: value,
                whatChange: newWhatChange
            };
        });
    };

    const addNewProductToLook = (_event) => {
        props.addProductToLook(contextId, expandedLookStatus, newProductState, false, newProductState.fromBarcode);
    };

    const confirmOnKeyPressHandler = (event) => {
        if (event.key === "Enter") {
            addNewProductToLook(event);
        }
    };

    const openConfirmDeleteLookModal = (event, lookId) => {
        event.stopPropagation();

        const look = looksState.find(look => look.id === lookId);
        setDeleteDialogState({
            ...deleteDialogState,
            title: intl.formatMessage({ id: 'showroom.dialog.title.delete.look', defaultMessage: 'Delete Look' }),
            body: intl.formatMessage({ id: 'showroom.dialog.body.delete.look', defaultMessage: '"Delete look:' }) + ' ' + look.name,
            open: true,
            item: look,
            onConfirm: deleteLook
        });
    }

    const openConfirmDeleteProductModal = (event, lookId, product) => {
        event.stopPropagation();

        setDeleteDialogState({
            ...deleteDialogState,
            title: intl.formatMessage({ id: 'showroom.dialog.title.delete.product', defaultMessage: 'Delete Product' }),
            body: intl.formatMessage({ id: 'showroom.dialog.body.delete.product', defaultMessage: '"Delete product:' }) + ' ' + product.style_fabric_color,
            open: true,
            item: { lookId, product },
            onConfirm: removeProductFromLook
        });
    }

    const clearAll = event => {
        props.clearProductAll();

        setNewProductState(prevState => ({
            ...prevState,
            barcode: '',
            style: '',
            fabric: '',
            color: '',
            styleFabricColor: '',
            fakeProduct: '',
            whatChange: null,
            fromBarcode: false,
        }));
    };

    useEffect(() => {
        console.log('CIAO! ', props.loadingLookProduct, props.errorLookProduct, props.typeLookProduct);
        if (!props.loadingLookProduct && props.errorLookProduct === null && props.typeLookProduct === 'POST' && props.productLookPlaceholder === null) {
            console.log('PULISCO!');
            clearAll();
        }
    }, [props.loadingLookProduct, props.errorLookProduct, props.typeLookProduct, props.productLookPlaceholder]);

    useEffect(() => {
        if (props.errorCurrentLookDetails) {
            console.log("Showing error message:", props.errorCurrentLookDetails);

            setSnackBarStatus(prevState => ({
                ...prevState,
                show: true,
                message: intl.formatMessage({
                    id: 'showroom.loading.look.products.list.error',
                    defaultMessage: 'Fetching look products list failed.'
                }),
                type: 'error',
                messageAbout: 'look_products'
            }));
        }
    }, [props.errorCurrentLookDetails])
    /** ************************************************************************************************************* */

    /**
     * Handler to close snackbar message and clear redux state to prevent the rendering of the same message.
     */
    const closeSnackbar = () => {
        switch (snackBarStatus.messageAbout) {
            case 'color':
                props.clearColors();
                break;
            case 'fabric':
                props.clearFabrics()
                break;
            case 'look_products':
                props.clearLookDetails();
                break;
            default:
                break;
        }

        setSnackBarStatus({
            show: false,
            message: '',
            type: '',
            messageAbout: ''
        })
    };


    const handleChangeNewLookTab = (event) => {
        setValueNewLookTab(event.target.value);
        console.log("multiple or single? ", event.target.value)

        if (event.target.value === 'single') {
            setLookDialogState((prevState => ({
                ...prevState,
                lookName: '',
                lookNote: '',
                to: 1,
                isMultiple: false

            })))
        } else {
            setLookDialogState((prevState => ({
                ...prevState,
                lookName: '',
                lookNote: '',
                to: 1,
                isMultiple: true

            })))
        }


    };

    return (
        <div>
            <Grid container
                direction="row"
                alignItems="flex-start"
                justify="space-around"
                spacing={4}>
                <Grid item xs={4}>
                    {contextId &&
                        <Grid container
                            direction={"row"}
                            justify="flex-end"
                            alignItems="flex-start"
                            className={classes.buttonContainerList}>
                            <>
                                <Grid item xs={11} />
                                <Grid item xs={1}>
                                    {checkAction("composition.add_look", props.auth.actions) &&
                                        <IconButtonElement
                                            icon={'add_box_icon'}
                                            onClick={(event) => openLookModalOnClick(event, true, null)}
                                            labelId={'showroom.tooltip.add.look'}
                                            label={'Add new look.'}
                                        />
                                    }
                                </Grid>
                            </>
                        </Grid>
                    }
                    <Grid container
                        direction={"row"}
                        justify={"flex-start"}
                        alignItems={"center"}
                        spacing={2}
                        className={classes.container}>
                        {
                            contextId &&
                            looksState &&
                            looksState.length > 0 &&
                            looksState.map(look => <Look key={look.id}
                                look={look}
                                expanded={look.id === expandedLookStatus}
                                digitalAssets={props.currentLookAssets}
                                approveLookHandler={approveLookOnClickHandler}
                                resetLookHandler={resetLookOnClickHandler}
                                editLookHandler={openLookModalOnClick}
                                onClickAccordion={onClickExpandAccordionHandler}
                                deleteLookHandler={openConfirmDeleteLookModal}
                            />)
                        }
                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    {
                        expandedLookStatus &&
                        checkAction("composition.add_new_product", props.auth.actions) &&
                        <div className={classes.newProductsContainer}>
                            <NewProduct barcodeOnChangeHandler={barcodeOnChangeHandler}
                                styleOnKeyPressHandler={styleOnKeyPressHandler}
                                styleOnChangeHandler={styleOnChangeHandler}
                                fabricOnChangeHandler={fabricOnChangeHandler}
                                colorOnChangeHandler={colorOnChangeHandler}
                                productCodeOnChangeHandler={styleFabricColorOnChangeHandler}
                                fakeProductCodeOnChangeHandler={fakeProductOnChangeHandler}
                                confirmOnKeyPressHandler={confirmOnKeyPressHandler}
                                addNewProductOnClickHandler={addNewProductToLook}
                                clearAll={clearAll}
                                values={newProductState}
                                loadingFabrics={props.loadingFabric}
                                loadingColors={props.loadingColor}
                                fabricOptions={props.fabrics}
                                colorOptions={props.colors}
                                dropdownsState={dropdownOpenState} />
                        </div>
                    }
                </Grid>
                <Grid item xs={4}>
                    <div className={classes.lookProductsContainer}>
                        {
                            expandedLookStatus &&
                            <DndLookShowroomProductsElement
                                contextId={contextId}
                                look={looksState.find(look => look.id === expandedLookStatus)}
                                deleteProductHandler={openConfirmDeleteProductModal}
                                updateProductHandler={mustBuyProductHandleChange}
                                editProductHandler={openProductModalOnClick}
                            />
                        }
                    </div>
                </Grid>
            </Grid>
            <ReusableModal
                reusableModal={{
                    title: deleteDialogState.title,
                    open: deleteDialogState.open
                }}
                closeReusableModal={resetDeleteDialogState}
                submitClick={() => deleteDialogState.onConfirm(deleteDialogState.item)}
            >
                {deleteDialogState.open && <Typography>{deleteDialogState.body}</Typography>}
            </ReusableModal>

            {
                lookDialogState.new ? (
                    <ReusableModal
                        reusableModal={lookDialogState}
                        submitClick={createLookOnClick}
                        closeReusableModal={() => {
                            setLookDialogState((prevState => ({
                                ...prevState,
                                open: false,
                                lookName: '',
                                lookNote: '',
                                lookId: null,
                                new: null,
                              
                                isMultiple: false,
                                from: 1,
                                to: 1
                            })))
                            setValueNewLookTab("single")
                        }
                        }>

                        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>


                                {/*
                                 <Tabs
                                    value={valueNewLookTab}
                                    onChange={handleChangeNewLookTab}
                                    aria-label="basic tabs example"
                                >
                                    <Tab label="Single look" />
                                    <Tab label="Multiple looks" />
                                </Tabs>
                                */}

                                <FormControl component="fieldset">
                                    <RadioGroup onChange={handleChangeNewLookTab} row name="row-radio-buttons-group">
                                        <FormControlLabel value="single" control={<Radio color="default" checked={valueNewLookTab === "single"} />} label="Single look" />
                                        <FormControlLabel value="multiple" control={<Radio color="default" checked={valueNewLookTab === "multiple"} />} label="Multiple look" />
                                    </RadioGroup>
                                </FormControl>

                            </Box>
                        </div>

                        <div hidden={valueNewLookTab !== "single"} >
                            <InputTextElement
                                labelId={"showroom.input.look_name.label"}
                                label={"Look Name"}
                                name={"look-name"}
                                handleChange={lookNameOnChangeHandler}
                                value={lookDialogState.lookName}
                                type='text'
                                fullWidth
                            />
                            <InputTextElement
                                labelId={"showroom.input.look_note.label"}
                                label={"Look Note"}
                                name={"look-note"}
                                handleChange={lookNoteOnChangeHandler}
                                value={lookDialogState.lookNote}
                                type='text'
                                fullWidth
                                multiline
                                rowsNumber={4}
                            />
                        </div>
                        <div hidden={valueNewLookTab !== "multiple"} >
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <InputTextElement
                                        labelId={"showroom.input.look_prefix.label"}
                                        label={"Look Prefix"}
                                        name={"look-prefix"}
                                        handleChange={lookNameOnChangeHandler}
                                        value={lookDialogState.lookName}
                                        type='text'
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputTextElement
                                        labelId={"showroom.input.look_number.label"}
                                        label={"Looks Number"}
                                        name={"looks-number"}
                                        handleChange={lookNumberOnChangeHandler}
                                        value={lookDialogState.to}
                                        type='number'
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>

                            <InputTextElement
                                labelId={"showroom.input.look_note.label"}
                                label={"Look Note"}
                                name={"look-note"}
                                handleChange={lookNoteOnChangeHandler}
                                value={lookDialogState.lookNote}
                                type='text'
                                fullWidth
                                multiline
                                rowsNumber={4}
                            />
                        </div>


                    </ReusableModal>
                ) : (
                    <ReusableModal
                        reusableModal={lookDialogState}
                        submitClick={updateLookOnClick}
                        closeReusableModal={() => setLookDialogState((prevState => ({
                            ...prevState,
                            open: false,
                            lookName: '',
                            lookNote: '',
                            lookId: null,
                            new: null,                            
                            isMultiple: false, from: 1, to: 1
                        })))}>
                        <InputTextElement
                            labelId={"showroom.input.look_name.label"}
                            label={"Look Name"}
                            name={"look-name"}
                            handleChange={lookNameOnChangeHandler}
                            value={lookDialogState.lookName}
                            type='text'
                            fullWidth
                        />
                        <InputTextElement
                            labelId={"showroom.input.look_note.label"}
                            label={"Look Note"}
                            name={"look-note"}
                            handleChange={lookNoteOnChangeHandler}
                            value={lookDialogState.lookNote}
                            type='text'
                            fullWidth
                            multiline
                            rowsNumber={4}
                        />
                    </ReusableModal>
                )
            }




            <ReusableModal
                reusableModal={productDialogState}
                submitClick={updateProductNoteOnClickHandler}
                closeReusableModal={() => setProductDialogState((prevState => ({
                    ...prevState,
                    open: false,
                    productNote: '',
                    productMustBuy: null,
                    productStyleFabricColor: null,
                    productLookId: null,
                    productPlaceholder: null
                })))}>
                <InputTextElement
                    labelId={"showroom.input.product_note.label"}
                    label={"Product Note"}
                    name={"product-note"}
                    handleChange={productNoteOnChangeHandler}
                    value={productDialogState.productNote}
                    type='text'
                    fullWidth
                    multiline
                    rowsNumber={4}
                />
            </ReusableModal>
            {snackBarStatus.show &&
                <SnackbarElement open={snackBarStatus.show}
                    type={snackBarStatus.type}
                    label={snackBarStatus.message}
                    handleClose={() => closeSnackbar()}
                    showDuration={7200000}
                />}
        </div>
    );
};


const mapStateToProps = state => ({
    auth: state.authReducer.auth,
    looks: state.looksReducer.looks,
    productLook: state.lookReducer.productLook,
    productLookPlaceholder: state.lookReducer.productLookPlaceholder,
    fabrics: state.fabricReducer.fabric ? _.orderBy(_.toArray(_.mapValues(state.fabricReducer.fabric, function (o) {
        return {
            label: o.fabric,
            value: o.fabric,
        };
    })),
        ['value'],
        ['asc']) : null,
    fabricError: state.fabricReducer.error,
    colors: state.colorReducer.color ? _.orderBy(_.toArray(_.mapValues(state.colorReducer.color, function (o) {
        return {
            label: o.color,
            value: o.color,
        };
    })),
        ['value'],
        ['asc']) : null,
    colorError: state.colorReducer.error,
    loadingFabric: state.fabricReducer.loadingFabric,
    loadingColor: state.colorReducer.loadingColor,
    loadingLookProduct: state.lookReducer.loadingLookProduct,
    errorLookProduct: state.lookReducer.errorLookProduct,
    errorCurrentLookDetails: state.lookReducer.errorCurrentLookDetails,
    currentLookAssets: state.lookReducer.currentLookAssets,
    typeLookProduct: state.lookReducer.typeLookProduct,
    productSFCInfo: state.productAllReducer
});

const mapDispatchToProps = dispatch => ({
    updateLook: (contextId, look, body) => dispatch(startPutLook(contextId, look, body)),
    createLook: (contextId, body, isMultiple) => dispatch(startPostLook(contextId, body, isMultiple)),
    updateLookProduct: (contextId, lookId, product, mustBuy) => dispatch(startUpdateProduct(contextId, lookId, product, mustBuy)),
    removeProduct: (contextId, lookId, product) => dispatch(startDeleteProduct(contextId, lookId, product)),
    removeLook: (contextId, lookId) => dispatch(startDeleteLook(contextId, lookId)),
    getFabrics: (style) => dispatch(startGetFabricByStyle(style)),
    addProductToLook: (contextId, lookId, product, mustBuy, fromBarcode) => dispatch(startAddProduct(contextId, lookId, product, mustBuy, fromBarcode)),
    getColors: (style, fabric) => dispatch(startGetColorsFromSF(style, fabric)),
    getLooks: (contextId) => dispatch(startGetLooks(contextId)),
    getLookDetails: (contextId, lookId) => dispatch(startGetCurrentLookDetails(contextId, lookId)),
    getProductAllS_F_C: (style_fabric_color) => dispatch(startGetProductAllS_F_C(style_fabric_color)),
    clearProductAll: () => dispatch(startClearProductAll()),
    clearColors: () => dispatch(startClearColorFromSF()),
    clearFabrics: () => dispatch(startClearFabricByStyle()),
    clearLookDetails: () => dispatch(clearCurrentLookDetails()),
    clearLookOrder: () => dispatch(clearProductLook()),
    clearLooks: () => dispatch(clearLooks()),
    clearLook: () => dispatch(clearLook())
});

export default connect(mapStateToProps, mapDispatchToProps)(LooksContainer);